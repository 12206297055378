import React, { useContext, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import { HeaderFlow, SlidePage, Input, Select, Text8, Button } from '..';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: 0 5px 0 0;
`;

const InputContainer = styled.span`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  & > svg {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  & > input {
    padding-left: 40px;
  }
`;

const formatPhone = (val) => {
  const p1 = val.slice(0, 3);
  const p2 = val.slice(3, 6);
  const p3 = val.slice(6);
  return `${p1}${p2.length > 0 ? '-' : ''}${p2}${
    p3.length > 0 ? '-' : ''
  }${p3}`;
};

const AccountInfo = (props) => {
  const gs = useContext(store);
  const { userDataConfig, testVacSelector, testConfig, userData } = gs.state;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;
  const dirRight = props.match.params.dir === 'l' ? false : true;

  useEffect(() => {
    if (userDataConfig) {
      let dataObj = {};
      userDataConfig.fields.forEach((f) => {
        if (f.confirm) {
          const dataFieldName = f.id + '_#confirm#';
          dataObj[dataFieldName] = '';
        }
        gs.dispatch({ type: 'set_userdata', data: dataObj });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (dataFieldName, value, rule) => {
    const dataObj = {};
    let fieldValue = value;
    if (rule && rule === 'phoneNoArea') {
      fieldValue = fieldValue.replace(/-/g, '');
    }
    dataObj[dataFieldName] = fieldValue;
    gs.dispatch({ type: 'set_userdata', data: dataObj });
  };

  const onIntlPhoneChange = (dataFieldName, value, rule) => {
    const dataObj = {};
    let fieldValue = '+' + value;
    dataObj[dataFieldName] = fieldValue;
    gs.dispatch({ type: 'set_userdata', data: dataObj });
  };

  const validField = (f, value, confirm) => {
    if (f.required) {
      if (
        value === null ||
        value === undefined ||
        (value.trim && value.trim() === '')
      )
        return false;
    }

    switch (f.rule) {
      case 'email':
        const regexEmail =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!value.match(regexEmail)) return false;
        break;
      case 'firstName':
      case 'lastName':
        const regexName = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        if (!value.match(regexName)) return false;
        break;
      case 'phoneNoArea':
        const regexPhoneNoArea = /^\d{10}$/;
        if (!value.match(regexPhoneNoArea)) return false;
        break;
      case 'phoneWithIntlCode':
        const regexPhoneWithIntlCode = /^\+(?:[0-9]●?){6,14}[0-9]$/;
        if (!value.match(regexPhoneWithIntlCode)) return false;
        break;
      default:
        break;
    }

    return true;
  };

  const onClickContinue = () => {
    if (userDataConfig) {
      let valid = true;

      for (let idx = 0; idx < userDataConfig.fields.length; idx++) {
        const f = userDataConfig.fields[idx];

        const dataFieldName = f.id;
        const box = document.getElementById(fieldId(f.id, 0));
        if (!validField(f, userData[dataFieldName])) {
          box.style.borderColor = 'var(--color-err-1)';
          box.scrollIntoView({ block: 'center', inline: 'nearest' });
          valid = false;
          break;
        } else {
          box.style.borderColor = 'var(--color-dark-3)';
        }

        if (f.confirm) {
          const dataFieldNameConf = f.id + '_#confirm#';
          const box = document.getElementById(fieldId(f.id, 1));
          if (!validField(f, userData[dataFieldNameConf])) {
            box.style.borderColor = 'var(--color-err-1)';
            box.scrollIntoView({ block: 'center', inline: 'nearest' });
            valid = false;
            break;
          } else {
            if (userData[dataFieldNameConf] !== userData[dataFieldName]) {
              box.style.borderColor = 'var(--color-err-1)';
              box.scrollIntoView({ block: 'center', inline: 'nearest' });
              valid = false;
              break;
            }
            box.style.borderColor = 'var(--color-dark-3)';
          }
        }
      }

      if (valid) {
        let forceAll = false;
        if (testVacSelector.forceAll === true) {
          forceAll = true;
        } else if (Array.isArray(testVacSelector.forceAll)) {
          const [fieldName, fieldVal] = testVacSelector.forceAll;
          if (userData[fieldName] && userData[fieldName] === fieldVal) {
            forceAll = true;
          }
        }

        if (forceAll) {
          gs.dispatch({ type: 'set_forceFlowAll', data: true });
          props.history.push(
            `/${clientPath}${eventPath ? '/' + eventPath : ''}/addvac`
          );
        } else {
          gs.dispatch({ type: 'set_forceFlowAll', data: false });
          const nextUrl = testVacSelector.show
            ? 'selproof'
            : testConfig.show
            ? 'addtest'
            : 'addvac';

          props.history.push(
            `/${clientPath}${eventPath ? '/' + eventPath : ''}/${nextUrl}`
          );
        }
      }
    }
  };

  const navBack = () => {
    const basePath = props.location.pathname.endsWith('/l')
      ? `/${clientPath}/${eventPath}/`
      : '';
    props.history.push(`${basePath}terms/l`);
  };

  const onClose = () => {
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  const fieldId = (id1, id2) => `__vc__rai__${id1}-${id2}__`;

  const getInputComponent = (f, id, confirm = false) => {
    let input = null;
    const dataFieldName = f.id + (confirm ? '_#confirm#' : '');
    let value = userData[dataFieldName] || '';

    if (f.rule === 'phoneNoArea') value = formatPhone(value);

    const fPlaceholder = confirm ? 'Confirm ' + f.label.toLowerCase() : f.label;

    switch (f.type) {
      case 'email':
      case 'text':
        input = (
          <InputContainer>
            {f.rule.toLowerCase().includes('phone') && (
              <i
                className='fad fa-phone-alt'
                style={{
                  height: '20px',
                  width: '20px',
                  color: 'var(--color-link)',
                }}
              ></i>
            )}
            {f.rule === 'email' && (
              <i
                className='fad fa-envelope'
                style={{
                  height: '20px',
                  width: '20px',
                  color: 'var(--color-link)',
                }}
              ></i>
            )}
            {(f.rule === 'firstName' || f.rule === 'lastName') && (
              <i
                className='fad fa-user-circle'
                style={{
                  height: '20px',
                  width: '20px',
                  color: 'var(--color-link)',
                }}
              ></i>
            )}
            {f.rule.toLowerCase() === 'phonewithintlcode' ? (
              <PhoneInput
                inputProps={{
                  id,
                }}
                className='IntlPhone'
                country={'us'}
                countryCodeEditable={false}
                value={value}
                placeholder={fPlaceholder}
                onChange={(e) => onIntlPhoneChange(dataFieldName, e, f.rule)}
                style={{
                  marginBottom: userDataConfig.fieldMarginBottom,
                  marginTop: '4px',
                }}
              />
            ) : (
              <Input
                id={id}
                type={f.type}
                value={value}
                placeholder={fPlaceholder}
                onChange={(e) =>
                  onInputChange(dataFieldName, e.target.value, f.rule)
                }
                style={{
                  marginBottom: userDataConfig.fieldMarginBottom,
                }}
              ></Input>
            )}
          </InputContainer>
        );
        break;
      case 'select':
        input = (
          <Select
            id={id}
            value={value}
            onChange={(e) =>
              onInputChange(dataFieldName, e.target.value, f.rule)
            }
            style={{
              marginBottom: userDataConfig.fieldMarginBottom,
            }}
          >
            {f.options.map((o, idx) => (
              <option key={id + idx}>{o}</option>
            ))}
          </Select>
        );
        break;
      case 'dob':
        input = (
          <InputContainer>
            <i
              className='fad fa-calendar'
              style={{
                height: '20px',
                width: '20px',
                color: 'var(--color-link)',
              }}
            ></i>
            <Input
              id={id}
              type='date'
              value={value}
              placeholder={fPlaceholder}
              onChange={(e) =>
                onInputChange(dataFieldName, e.target.value, f.rule)
              }
              style={{
                marginBottom: userDataConfig.fieldMarginBottom,
              }}
            ></Input>
          </InputContainer>
        );
        break;
      default:
        break;
    }

    return input;
  };

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        <HeaderFlow
          label='Contact Information'
          onLeave={navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        <ContentContainer
          style={{
            marginTop: userDataConfig && userDataConfig.infoAreaTopMargin,
          }}
        >
          {userDataConfig && (
            <>
              {userDataConfig.info &&
                userDataConfig.info.split('#-#').map((l, idx) => (
                  <Text8
                    key={idx}
                    style={{
                      marginBottom: userDataConfig.infoAreaBottomMargin,
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {l}
                  </Text8>
                ))}
              <Text8 style={{ margin: '0 0 10px 0' }}>
                <b>
                  {userDataConfig && userDataConfig.submission_msg
                    ? userDataConfig.submission_msg
                    : 'A separate submission must be done for EACH individual/attendee.'}
                </b>
              </Text8>
              {userDataConfig.fields.map((f) => {
                const id0 = fieldId(f.id, 0);
                const id1 = fieldId(f.id, 1);

                const inp1 = (
                  <Fragment key={id0}>
                    {f.note && <Text8>{f.note}</Text8>}
                    {getInputComponent(f, id0)}
                  </Fragment>
                );

                const inp2 = f.confirm ? (
                  <Fragment key={id1}>
                    {f.note && <Text8>Confirm {f.note}</Text8>}
                    {getInputComponent(f, id1, true)}
                  </Fragment>
                ) : null;

                return [inp1, inp2];
              })}
            </>
          )}
        </ContentContainer>
        <Button onClick={onClickContinue}>Continue</Button>
      </PageContainer>
    </SlidePage>
  );
};

export { AccountInfo };
