import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { imageCDN } from '../config';

const Host = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 0;
  background: no-repeat url('${imageCDN}asback.png');
  background-size: 100% auto;
  background-color: #f5f5f5;
  overflow: hidden;

  @media screen and (min-width: 500px) {
    max-width: 500px;
  }

  @media screen and (min-height: 1000px) {
    max-height: 1000px;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  width: calc(100% - 80px);
  height: calc(100% - 60px);
  padding: 15px 25px;
  transition: transform 0.3s ease-out;
  background-color: #ffffff;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 2px 10px 25px rgba(0, 0, 0, 0.1);
`;

const SlidePage = (props) => {
  const { right = false } = props;
  const pid = uuidv4();

  const [isOpen, setIsOpen] = useState(false);

  const transitionStyles = {
    entering: { transform: 'translateX(0)' },
    entered: { transform: 'translateX(0)' },
    exiting: {
      transform: right ? 'translateX(calc(100%))' : 'translateX(calc(-100%))',
    },
    exited: {
      transform: right ? 'translateX(calc(100%))' : 'translateX(calc(-100%))',
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
  }, []);

  return (
    <Transition in={isOpen} timeout={300}>
      {(state) => (
        <Host>
          <Container
            id={pid}
            style={{
              ...transitionStyles[state],
            }}
          >
            {props.children}
          </Container>
        </Host>
      )}
    </Transition>
  );
};

export { SlidePage };
