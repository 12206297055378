import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import { SlidePage, Text4, Button } from '..';
import { formatText } from '../../utils';
import { imageCDN, clientImageCDN } from '../../config';

const PageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  text-align: center;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
  background-color: rgba(255, 255, 255, 1);
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const LogoContainer = styled.div`
  display: grid;
  grid-template-rows: ${(props) => props.r1} ${(props) => props.r2};
  align-items: center;
  margin-bottom: ${(props) => props.bottomMargin};
  height: ${(props) => props.h};
  overflow-x: hidden;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
`;

const InfoContainer = styled.div`
  text-align: left;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

const LandingPage = (props) => {
  const gs = useContext(store);
  const { landingInfo, clientInfo } = gs.state;

  useEffect(() => {
    gs.dispatch({ type: 'set_inited' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navToPrivacyPolicy = () => {
    const eventPath = props.match.params.event;
    const basePath = props.location.pathname.endsWith('/')
      ? ''
      : `${eventPath}/`;
    props.history.push(`${basePath}terms`);
  };

  return (
    <SlidePage>
      <PageContainer>
        <ContentContainer>
          <LogoContainer
            r1={landingInfo.logo.show ? landingInfo.logo.row : ''}
            r2={landingInfo.clientlogo.show ? landingInfo.clientlogo.row : ''}
            h={landingInfo.logoContainerHeight || 'auto'}
            bottomMargin={landingInfo.logoAreaBottomMargin}
          >
            {landingInfo && landingInfo.logo.show && (
              <Image>
                <img
                  width={landingInfo.logo.w}
                  height={landingInfo.logo.h}
                  src={`${imageCDN}${landingInfo.logo.name}`}
                  alt='logo'
                />
              </Image>
            )}
            {landingInfo && landingInfo.clientlogo.show && (
              <Image>
                <img
                  width={landingInfo.clientlogo.w}
                  height={landingInfo.clientlogo.h}
                  src={`${clientImageCDN}${landingInfo.clientlogo.name}`}
                  alt='client logo'
                />
              </Image>
            )}
          </LogoContainer>
          <InfoContainer
            marginTop={landingInfo.infoAreaTopMargin}
            marginBottom={landingInfo.infoAreaBottomMargin}
          >
            <Text4 style={{ whiteSpace: 'pre-line' }}>
              {landingInfo &&
                landingInfo.title &&
                landingInfo.title.split('#-#').map((l, idx) => (
                  <Text4
                    key={idx}
                    style={{ marginTop: '20px', whiteSpace: 'pre-line' }}
                  >
                    {formatText(l).map((f) => f)}
                  </Text4>
                ))}
            </Text4>
            {landingInfo &&
              landingInfo.info &&
              landingInfo.info
                .replace('#event#', clientInfo.title || '')
                .replace('#dates#', clientInfo.textDates || '')
                .split('#-#')
                .map((l, idx) => (
                  <Text4
                    key={idx}
                    style={{ marginTop: '25px', whiteSpace: 'pre-line' }}
                  >
                    {formatText(l).map((f) => f)}
                  </Text4>
                ))}
            <Text4 style={{ marginTop: '15px' }}>
              <b>
                {landingInfo && landingInfo.submission_msg
                  ? landingInfo.submission_msg
                  : 'A separate submission must be done for EACH individual/attendee.'}
              </b>
            </Text4>
          </InfoContainer>
          <Button onClick={navToPrivacyPolicy}>Continue</Button>
        </ContentContainer>
      </PageContainer>
    </SlidePage>
  );
};

export { LandingPage };
