import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTable, useFilters, useSortBy } from 'react-table';
import Select from 'react-dropdown-select';
import { imageCDN } from '../../config';
import { getDashClientList, getDashData, getUserInfo } from '../../api';
import { Link, Error, Overlay, UserDetail } from '..';

const PageContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  box-sizing: border-box;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  background-color: var(--color-dark-3);
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  justify-content: start;
  grid-gap: 10px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;

  & > span {
    font-weight: bold;
    font-size: 1.7rem;
    color: var(--color-dark-1);
    user-select: none;
  }

  & > img {
    cursor: pointer;
  }
`;

const Summary = styled.div`
  margin: 0 0 0 50px;
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 0 20px;
  align-items: center;

  & > span {
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--color-dark-1);
    user-select: none;
  }
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-rows: 1fr;
  box-sizing: border-box;
`;

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const ClientsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 1fr 35px;
  grid-gap: 5px 10px;
  margin-bottom: 30px;
`;

const Image = styled.div`
  margin: 5px 0;
`;

const NoDataMsg = styled.div`
  font-weight: bold;
  font-size: 4rem;
  color: var(--color-dark-3);
  user-select: none;
  margin: 50px auto;
`;

const UserTableContainer = styled.div`
  padding: 0;

  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 200px);
    overflow-x: scroll;
    overflow-y: auto;
    border-bottom: 1px solid black;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid var(--color-dark-2);
    border-radius: 2px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tr:hover {
      background-color: ${(props) =>
        props.role === 'superadmin' || props.role === 'admin'
          ? 'rgba(0,0,0,0.05)'
          : ''};
      cursor: ${(props) =>
        props.role === 'superadmin' || props.role === 'admin' ? 'pointer' : ''};
    }

    th,
    td {
      user-select: none;
      margin: 0;
      padding: 10px 5px;
      border-bottom: 1px solid var(--color-dark-3);
      border-right: 1px solid var(--color-dark-3);

      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
        padding-right: 20px;
      }
    }

    th {
      border-bottom: 3px solid var(--color-dark-3);
      padding: 15px 5px;
      text-align: left;
    }
  }
`;

const DetailOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: grid;
  grid-template-rows: minmax(1fr, 85%);
  grid-template-columns: minmax(1fr, 85%);
  align-content: center;
  justify-content: center;
`;

const formatPhone = (val) => {
  if (!val) return '';
  const p1 = val.slice(0, 3);
  const p2 = val.slice(3, 6);
  const p3 = val.slice(6);
  return `${p1}${p2.length > 0 ? '-' : ''}${p2}${
    p3.length > 0 ? '-' : ''
  }${p3}`;
};

const Dashboard = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [userName, setUserName] = useState('');
  const [userNameDescr, setUserNameDescr] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState('');
  const [selectedEvent, setSelectedEvent] = useState({});
  const [clientRecs, setClientRecs] = useState([]);
  const [columns, setColumns] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getUserInfo().then((res) => {
      if (res) {
        setSpinner(true);
        setIsAuth(true);
        setUserName(res.userDetails);
        setUserNameDescr('');

        getDashClientList()
          .then((res) => {
            const recs = res.data.clientRecs
              .sort((a, b) => {
                const _a =
                  a.clientCode.toUpperCase() + '/' + a.id.toUpperCase();
                const _b =
                  b.clientCode.toUpperCase() + '/' + b.id.toUpperCase();
                if (_a < _b) {
                  return -1;
                }
                if (_a > _b) {
                  return 1;
                }
                return 0;
              })
              .map((r) => ({
                ...r,
                event: r.id,
                name: `${r.title} on ${r.startDate}  (${r.clientCode}/${r.id})`,
                id: r.guid,
              }));
            setClientRecs(recs);
          })
          .catch(() => setErr('Not Authorized'))
          .finally(() => {
            setSpinner(false);
          });
      } else {
        setIsAuth(false);
      }
    });
  }, []);

  const selectEvent = (events) => {
    setSelectedEvent(events[0]);
    setErr('');
    if (events.length > 0) {
      setSpinner(true);

      getDashData(events[0].clientCode, events[0].event)
        .then((res) => {
          setUserRole(res.data.role);
          let columns = [];
          setColumns(columns);

          const hasNotes = res.data.items.some((x) => x.note);

          columns.push({
            Header: 'Submit Date',
            accessor: 'submitDate',
            Filter: SelectColumnFilter,
            filter: 'includes',
          });
          columns.push(
            ...events[0].fields
              .sort((a, b) => (a.dashOrder < b.dashOrder ? -1 : 1))
              .map((f) => ({
                Header: f.dashName,
                accessor: `userData.${f.id}`,
                Cell: (props) => {
                  if (
                    f.rule === 'phoneNoArea' ||
                    (f.rule === 'phoneWithIntlCode' &&
                      !props.value.startsWith('+'))
                  )
                    return formatPhone(props.value);
                  return props.value;
                },
                filter: 'text',
              }))
          );
          columns.push({
            Header: 'Status',
            accessor: 'status',
            Filter: SelectStatusFilter,
            filter: 'includes',
          });
          if (
            res.data.role === 'superadmin' ||
            res.data.role === 'admin' ||
            hasNotes
          ) {
            columns.push({
              Header: 'Note',
              accessor: 'note',
              Filter: SelectColumnFilter,
              filter: 'includes',
            });
            columns.push({
              Header: 'Verified By',
              accessor: 'verifiedBy',
              Filter: SelectColumnFilter,
              filter: 'includes',
            });
            columns.push({
              Header: 'Verified On',
              accessor: 'verifiedOn',
            });
          }

          setColumns(columns);

          let maxCols = Math.max(
            ...res.data.items
              .filter((i) => i.userData && Object.keys(i.userData).length > 0)
              .map((i) => Object.keys(i.userData).length)
          );

          setUserData(
            res.data.items
              .filter(
                (i) =>
                  i.userData &&
                  Object.keys(i.userData).length > 0 &&
                  Object.keys(i.userData).length >= maxCols
              )
              .map((i) => {
                return {
                  ...i,
                  submitDate: moment
                    .utc(
                      i.submitDate.substring(0, 19).replace('T', ''),
                      'YYYY-MM-DDHH:mm:ss'
                    )
                    .utcOffset(-6)
                    .format('M/D/YYYY h:mm:ss A'),
                  status: i.status || '',
                  note: i.note || '',
                  verifiedBy: i.verifiedBy || '',
                  verifiedOn: i.verifiedOn || '',
                };
              })
          );
        })
        .catch((err) => {
          const { status } = err.response || { status: 999 };
          switch (status) {
            case 401:
              setErr('Not Authorized');
              break;
            default:
              setErr('Failed to read user submission data');
              break;
          }
        })
        .finally(() => {
          setSpinner(false);
        });
    } else {
      setUserData([]);
    }
  };

  const onRefresh = () => {
    if (selectedEvent && selectedEvent.clientCode) {
      selectEvent([selectedEvent]);
    }
  };

  const exportData = () => {
    const cvsColumns = ['Submit Date', 'Status'];
    cvsColumns.push(
      columns
        .filter(
          (c) =>
            c.accessor.startsWith('userData.') ||
            c.accessor === 'verifiedBy' ||
            c.accessor === 'verifiedOn'
        )
        .map((c) => c.Header)
    );

    const data = userData.map((d) => {
      const r = [`"${d.submitDate}"`, d.status];
      r.push(
        ...columns
          .filter((c) => c.accessor.startsWith('userData.'))
          .map((c) => c.accessor.replace('userData.', ''))
          .map((c) => `"${d.userData[c]}"`)
      );
      r.push(
        ...columns
          .filter(
            (c) => c.accessor === 'verifiedBy' || c.accessor === 'verifiedOn'
          )
          .map((c) => `"${d[c.accessor]}"`)
      );
      return r.join(',');
    });

    let csv = cvsColumns.join(',') + '\n' + data.join('\n');

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=UTF-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'as_dashboard.csv';
    hiddenElement.click();
  };

  const onRowClick = (row) => {
    row.original && row.original.userData && row.original.id
      ? setSelectedUser(row.original)
      : setSelectedUser(null);
  };

  const onClickDetailOverlay = () => {
    setSelectedUser(null);
  };

  const onStatusChange = (id, status, by, on) => {
    const tempUsers = [...userData];
    const tempUserIdx = tempUsers.findIndex((x) => x.id === id);
    if (tempUserIdx >= 0) {
      tempUsers[tempUserIdx].status = status;
      tempUsers[tempUserIdx].verifiedBy = by;
      tempUsers[tempUserIdx].verifiedOn = on;
      setUserData(tempUsers);
    }
  };

  const onDeleteRec = () => {
    onRefresh();
  };

  const onNoteChange = (id, note) => {
    const tempUsers = [...userData];
    const tempUserIdx = tempUsers.findIndex((x) => x.id === id);
    if (tempUserIdx >= 0) {
      tempUsers[tempUserIdx].note = note;
      setUserData(tempUsers);
    }
  };

  const onTestDateChange = (id, testDate) => {
    const tempUsers = [...userData];
    const tempUserIdx = tempUsers.findIndex((x) => x.id === id);
    if (tempUserIdx >= 0) {
      tempUsers[tempUserIdx].testData.testDate = testDate;
      setUserData(tempUsers);
    }
  };

  const onExempDateChange = (id, exempDate) => {
    const tempUsers = [...userData];
    const tempUserIdx = tempUsers.findIndex((x) => x.id === id);
    if (tempUserIdx >= 0) {
      tempUsers[tempUserIdx].exempData.exempDate = exempDate;
      setUserData(tempUsers);
    }
  };

  const onVaccineChange = (id, vacTypeId, vacDates) => {
    const tempUsers = [...userData];
    const tempUserIdx = tempUsers.findIndex((x) => x.id === id);
    if (tempUserIdx >= 0) {
      tempUsers[tempUserIdx].vacData.vacType = vacTypeId;
      tempUsers[tempUserIdx].vacData.vacDates = vacDates;
      setUserData(tempUsers);
    }
  };

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    return (
      <select
        style={{ marginTop: '10px', height: '22px' }}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value='' key='-1'>
          All
        </option>
        {options
          .filter((option) => option)
          .map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
      </select>
    );
  }

  function SelectStatusFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = ['Verified', 'Rejected'];

    return (
      <select
        style={{ marginTop: '10px', height: '22px' }}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value='' key='-1'>
          All
        </option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  return (
    <PageContainer>
      {spinner && <Overlay></Overlay>}
      {selectedUser && (
        <DetailOverlay onClick={onClickDetailOverlay}>
          <UserDetail
            user={selectedUser}
            role={userRole}
            closeDetail={onClickDetailOverlay}
            statusChange={onStatusChange}
            noteChange={onNoteChange}
            vaccineChange={onVaccineChange}
            testDateChange={onTestDateChange}
            exempDateChange={onExempDateChange}
            deleteRec={onDeleteRec}
            event={selectedEvent}
          />
        </DetailOverlay>
      )}
      <Header>
        <Image>
          <img
            width='160px'
            height='25px'
            src={`${imageCDN}attendsafe_logo.png`}
            alt='Logo'
          />
        </Image>
        <span>Admin Dashboard</span>
        <Summary>
          {isAuth ? (
            <Link>
              <a href='/.auth/logout?post_logout_redirect_uri=https://attendsafe.app/dashboard'>
                {/* <a href='/.auth/logout?post_logout_redirect_uri=http://localhost:4280/dashboard'> */}
                Logout
              </a>
            </Link>
          ) : (
            <Link>
              <a href='/.auth/login/aad?post_login_redirect_uri=https://attendsafe.app/dashboard'>
                {/* <a href='/.auth/login/aad?post_login_redirect_uri=http://localhost:4280/dashboard'> */}
                Login
              </a>
            </Link>
          )}
          {isAuth && <Link onClick={exportData}>Export</Link>}
          <span></span>
          <span>
            {userName} {userNameDescr}
          </span>
        </Summary>
      </Header>
      <Container>
        <Content>
          <ClientsContainer>
            <Error>{err}</Error>
            <span />
            {clientRecs && clientRecs.length > 0 && (
              <>
                <Select
                  placeholder='Select event'
                  addPlaceholder='+ type to search'
                  options={clientRecs}
                  labelField='name'
                  valueField='guid'
                  loading={false}
                  searchable={true}
                  noDataLabel='No matches found'
                  searchBy='name'
                  clearable={true}
                  multi={false}
                  keepSelectedInList={true}
                  color='rgba(92,116,163)'
                  clearOnBlur={true}
                  clearOnSelect={true}
                  closeOnSelect={true}
                  onChange={(values) => selectEvent(values)}
                ></Select>
                <Image>
                  <img
                    width='25px'
                    height='25px'
                    src={`${imageCDN}refresh.png`}
                    alt='refresh'
                    style={{ cursor: 'pointer' }}
                    onClick={onRefresh}
                  />
                </Image>
              </>
            )}
          </ClientsContainer>
          {userData && userData.length > 0 && columns && columns.length > 0 ? (
            <UserTableContainer role={userRole}>
              <div className='tableWrap'>
                <UserInfoTable
                  columns={columns}
                  data={userData}
                  getRowProps={(row) => ({
                    style: {
                      color:
                        row.values.status === 'Rejected'
                          ? 'var(--color-err-1)'
                          : row.values.status === 'Verified'
                          ? 'var(--color-success-1)'
                          : 'var(--color-dark-1)',
                    },
                  })}
                  onRowClick={onRowClick}
                />
              </div>
            </UserTableContainer>
          ) : (
            <NoDataMsg>No Data</NoDataMsg>
          )}
        </Content>
      </Container>
    </PageContainer>
  );
};

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`...`}
      style={{ marginTop: '10px' }}
    />
  );
}

const defaultPropGetter = () => ({});

function UserInfoTable({
  columns,
  data,
  getRowProps = defaultPropGetter,
  onRowClick,
}) {
  const filterTypes = React.useMemo(
    () => ({
      startsWith: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
        filterTypes,
        autoResetSortBy: false,
        autoResetFilters: false,
      },
      useFilters,
      useSortBy
    );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th key={i}>
                <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        ' ▼'
                      ) : (
                        ' ▲'
                      )
                    ) : (
                      <>
                        <span
                          style={{
                            fontSize: '14px',
                            color: 'var(--color-dark-2',
                          }}
                        >
                          {' '}
                          ▲
                        </span>
                        <span
                          style={{
                            fontSize: '14px',
                            marginLeft: '-9px',
                            color: 'var(--color-dark-2',
                          }}
                        >
                          {' '}
                          ▼
                        </span>
                      </>
                    )}
                  </span>
                </div>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps(getRowProps(row))}
              onClick={() => onRowClick(row)}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { Dashboard };
