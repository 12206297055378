import React, { useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  Landing,
  NoRouteMatch,
  PrivacyPolicy,
  AccountInfo,
  SelectProof,
  AddTest,
  AddVaccine,
  UploadTest,
  UploadVaccine,
  AddExemp,
  UploadExemp,
  GoodToGo,
  Dashboard,
} from './components';
import { store } from './store.js';
import { getClientInfo } from './api';

const App = (props) => {
  const gs = useContext(store);
  const { inited } = gs.state;

  const appCodes = useLocation()
    .pathname.split('/')
    .filter((x) => x);

  const currLoc = useLocation().pathname;

  const clientCode = (appCodes[0] || '').toLowerCase();
  const eventCode = (appCodes[1] || '').toLowerCase();

  useEffect(() => {
    if (clientCode !== 'dashboard') {
      getClientInfo(clientCode, eventCode)
        .then((res) => {
          if (res && res.data) {
            const { config, clientCode, id, guid } = res.data;
            gs.dispatch({
              type: 'set_client_info',
              data: {
                clientCode,
                clientId: id,
                clientGuid: guid,
                client: config.client,
                title: config.title,
                startDate: config.startDate,
                textDates: config.textDates,
              },
            });
            gs.dispatch({ type: 'set_landing_info', data: config.landing });
            gs.dispatch({ type: 'set_userdata_config', data: config.userData });
            gs.dispatch({
              type: 'set_testVacSelector',
              data: config.testVacSelector,
            });
            gs.dispatch({ type: 'set_test_config', data: config.test });
            gs.dispatch({
              type: 'set_exemp_config',
              data: config.exemption || {},
            });
            gs.dispatch({ type: 'set_vac_config', data: config.vaccine });
          }
        })
        .catch(() => {
          gs.dispatch({ type: 'set_landing_info', data: null });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {currLoc !== `/${clientCode}/${eventCode}` && !inited && (
        <Redirect to={'/' + clientCode + '/' + eventCode} />
      )}
      <Route exact path='/dashboard' component={Dashboard} />
      <Route
        exact
        path='/:client/:event/terms/:dir?'
        component={PrivacyPolicy}
      />
      <Route exact path='/:client/terms/:dir?' component={PrivacyPolicy} />
      <Route
        exact
        path='/:client/:event/regaccount/:dir?'
        component={AccountInfo}
      />
      <Route exact path='/:client/regaccount/:dir?' component={AccountInfo} />
      <Route
        exact
        path='/:client/:event/selproof/:dir?'
        component={SelectProof}
      />
      <Route exact path='/:client/selproof/:dir?' component={SelectProof} />
      <Route exact path='/:client/:event/addtest/:dir?' component={AddTest} />
      <Route exact path='/:client/addtest/:dir?' component={AddTest} />
      <Route exact path='/:client/:event/addvac/:dir?' component={AddVaccine} />
      <Route exact path='/:client/addvac/:dir?' component={AddVaccine} />
      <Route exact path='/:client/:event/addexemp/:dir?' component={AddExemp} />
      <Route exact path='/:client/addvac/:dir?' component={AddExemp} />
      <Route
        exact
        path='/:client/:event/uploadt/:dir?'
        component={UploadTest}
      />
      <Route exact path='/:client/uploadt/:dir?' component={UploadTest} />
      <Route
        exact
        path='/:client/:event/uploadv/:dir?'
        component={UploadVaccine}
      />
      <Route exact path='/:client/uploadv/:dir?' component={UploadVaccine} />
      <Route
        exact
        path='/:client/:event/uploade/:dir?'
        component={UploadExemp}
      />
      <Route exact path='/:client/uploade/:dir?' component={UploadExemp} />
      <Route exact path='/:client/:event/gtg' component={GoodToGo} />
      <Route exact path='/:client/gtg' component={GoodToGo} />
      <Route exact path='/:client/:event?' component={Landing} />
      <Route path='*'>
        <NoRouteMatch />
      </Route>
    </Switch>
  );
};

export default App;
