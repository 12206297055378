import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import { HeaderFlow, SlidePage, Text5, Text7, Photo, Button, Error } from '..';
import { imageCDN } from '../../config';
import { formatText } from '../../utils';

const ImgPreview = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PdfPreview = styled.object`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
`;

const TestContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const UploadVaccine = (props) => {
  const gs = useContext(store);
  const { vacConfig, vacData } = gs.state;

  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;
  const dirRight = props.match.params.dir === 'l' ? false : true;

  const [uploaded, setUploaded] = useState(null);
  const [imgType, setImgType] = useState(
    vacData.vacImage ? vacData.vacImage.type : 'image'
  );
  const [err, setErr] = useState('');

  useEffect(() => {
    const imgSel = document.getElementById('__vc_img_uplvac2_sel__');
    imgSel.addEventListener('change', onImageSelect);

    const imgCont = document.getElementById('__vc_img_uplvac2_displ__');

    if (vacData.vacImage && imgCont) {
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (vacData.vacImage.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(vacData.vacImage);
      setUploaded(vacData.vacImage);
    } else if (imgCont) {
      imgCont.src = '';
    }

    return () => {
      imgSel.removeEventListener('change', onImageSelect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageSelect = (e) => {
    const files = e.target.files;
    const image = (files && files[0]) || null;
    setImgType(image ? image.type : 'image');

    const imgCont = document.getElementById('__vc_img_uplvac2_displ__');
    if (image && imgCont) {
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (image.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(image);
      setUploaded(image);
    } else if (imgCont) {
      imgCont.src = '';
      setUploaded(null);
    }
  };

  const navBack = (reset = true) => {
    if (reset) {
      gs.dispatch({ type: 'set_vacImage', data: null });
    }
    const basePath = props.location.pathname.endsWith('/l')
      ? `/${clientPath}${eventPath ? '/' + eventPath : ''}/`
      : '';
    props.history.push(`${basePath}addvac`);
  };

  const onUploadClick = () => {
    if (!uploaded) {
      setErr(`Please upload image`);
      return;
    }
    gs.dispatch({ type: 'set_vacImage', data: uploaded });
    navBack(false);
  };

  const onClose = () => {
    gs.dispatch({ type: 'set_vacImage', data: null });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        <HeaderFlow
          label='Upload Vaccine Records'
          onLeave={navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        <TestContainer>
          {vacConfig &&
            vacConfig.uploadTitle &&
            vacConfig.uploadTitle.split('#-#').map((l, idx) => (
              <Text5
                key={idx}
                style={{ marginBottom: '12px', whiteSpace: 'pre-line' }}
              >
                {formatText(l).map((f) => f)}
              </Text5>
            ))}
          {vacConfig &&
            vacConfig.uploadInfo &&
            vacConfig.uploadInfo.split('#-#').map((l, idx) => (
              <Text7
                key={idx}
                style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
              >
                {l}
              </Text7>
            ))}
          {err && <Error style={{ margin: '20px 0 10px 0' }}>{err}</Error>}
          <Photo>
            <img alt='camera' src={`${imageCDN}camera.png`}></img>
            <span>Choose an image</span>
            <input
              id='__vc_img_uplvac2_sel__'
              type='file'
              accept='image/*,application/pdf'
            />
            {imgType === 'application/pdf' && (
              <PdfPreview
                id='__vc_img_uplvac2_displ__'
                style={{ opacity: uploaded ? 1 : 0 }}
              />
            )}
            {imgType !== 'application/pdf' && (
              <ImgPreview
                id='__vc_img_uplvac2_displ__'
                style={{ opacity: uploaded ? 1 : 0 }}
              />
            )}
          </Photo>
        </TestContainer>
        <Button onClick={onUploadClick}>Review Submission</Button>
      </PageContainer>
    </SlidePage>
  );
};

export { UploadVaccine };
