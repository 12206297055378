import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import { HeaderFlow, SlidePage, Text5, Text7, Photo, Button, Error } from '..';
import { formatText } from '../../utils';

const ImgPreview = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
`;

const ExempContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const UploadExemp = (props) => {
  const gs = useContext(store);
  const { exempConfig, exempData } = gs.state;

  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;
  const dirRight = props.match.params.dir === 'l' ? false : true;

  const [uploaded, setUploaded] = useState(null);
  const [err, setErr] = useState('');

  useEffect(() => {
    const imgSel = document.getElementById('__vc_img_uplexemp2_sel__');
    imgSel.addEventListener('change', onImageSelect);

    const imgCont = document.getElementById('__vc_img_uplexemp2_displ__');

    if (exempData.exempImage && imgCont) {
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        imgCont.src = e.target.result;
      };
      imgReader.readAsDataURL(exempData.exempImage);
      setUploaded(exempData.exempImage);
    } else if (imgCont) {
      imgCont.src = '';
    }

    return () => {
      imgSel.removeEventListener('change', onImageSelect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageSelect = (e) => {
    const files = e.target.files;
    const image = (files && files[0]) || null;

    const imgCont = document.getElementById('__vc_img_uplexemp2_displ__');
    if (image && imgCont) {
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        imgCont.src = e.target.result;
      };
      imgReader.readAsDataURL(image);
      setUploaded(image);
    } else if (imgCont) {
      imgCont.src = '';
      setUploaded(null);
    }
  };

  const navBack = (reset = true) => {
    if (reset) {
      gs.dispatch({ type: 'set_exempImage', data: null });
    }
    const basePath = props.location.pathname.endsWith('/l')
      ? `/${clientPath}${eventPath ? '/' + eventPath : ''}/`
      : '';
    props.history.push(`${basePath}addexemp`);
  };

  const onUploadClick = () => {
    if (!uploaded) {
      setErr(`Please upload image`);
      return;
    }
    gs.dispatch({ type: 'set_exempImage', data: uploaded });
    navBack(false);
  };

  const onClose = () => {
    gs.dispatch({ type: 'set_exempImage', data: null });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        <HeaderFlow
          label='Upload Medical/Religious Exemption Documentation'
          onLeave={navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        <ExempContainer>
          {exempConfig &&
            exempConfig.uploadTitle &&
            exempConfig.uploadTitle.split('#-#').map((l, idx) => (
              <Text5
                key={idx}
                style={{ marginBottom: '12px', whiteSpace: 'pre-line' }}
              >
                {formatText(l).map((f) => f)}
              </Text5>
            ))}
          {exempConfig &&
            exempConfig.uploadInfo &&
            exempConfig.uploadInfoLocation === 'top' &&
            exempConfig.uploadInfo.split('#-#').map((l, idx) => (
              <Text7
                key={idx}
                style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}
              >
                {formatText(l).map((f) => f)}
              </Text7>
            ))}
          {err && <Error style={{ margin: '10px 0' }}>{err}</Error>}
          <Photo>
            <i
              className='fal fa-camera-alt'
              style={{
                height: '40px',
                width: '40px',
                color: 'var(--color-link)',
                marginBottom: '10px',
              }}
            ></i>
            <span>Choose an image</span>
            <input
              id='__vc_img_uplexemp2_sel__'
              type='file'
              accept='image/*,application/pdf'
            />
            <ImgPreview
              id='__vc_img_uplexemp2_displ__'
              style={{ opacity: uploaded ? 1 : 0 }}
            />
          </Photo>
          {exempConfig &&
            exempConfig.uploadInfo &&
            (!exempConfig.uploadInfoLocation ||
              exempConfig.uploadInfoLocation === 'bottom') &&
            exempConfig.uploadInfo.split('#-#').map((l, idx) => (
              <Text7
                key={idx}
                style={{ marginTop: '10px', whiteSpace: 'pre-line' }}
              >
                {formatText(l).map((f) => f)}
              </Text7>
            ))}
        </ExempContainer>
        <Button onClick={onUploadClick}>Review Submission</Button>
      </PageContainer>
    </SlidePage>
  );
};

export { UploadExemp };
