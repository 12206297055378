import React, { useContext, useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { store } from '../../store.js';
import {
  SlidePage,
  HeaderFlow,
  Link,
  DateInput,
  Error,
  Text3,
  Text5,
  Text6,
  Button,
  Overlay,
} from '..';
import { createUserRec, storeImage } from '../../api';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
`;

const VacContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 0px;
  margin-bottom: 25px;
`;

const Option = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-template-areas: 'text icon';
  grid-gap: 0 6px;
  align-items: center;
  align-content: center;
  padding: 0 11px;
  border: 1px solid var(--color-dark-2);
  background-color: var(--color-nav-2);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  height: 94px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
`;

const ImgPreview = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

const PdfPreview = styled.object`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

const AddVaccine = (props) => {
  const gs = useContext(store);
  const {
    testVacSelector,
    vacConfig,
    vacData,
    userData,
    clientInfo,
    forceFlowAll,
  } = gs.state;

  const dirRight = props.match.params.dir === 'l' ? false : true;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;

  const [datesNum, setDatesNum] = useState(true);
  const [optionalShots, setOptionalShots] = useState([]);
  const [err, setErr] = useState('');
  const [errAccount, setErrAccount] = useState(false);
  const [review, setReview] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  function checkDobReqs() {
    if (vacConfig.checkDOB && userData.dob) {
      const age = calculateAge(userData.dob);
      if (age < vacConfig.checkDOB) {
        return 0;
      } else {
        return 1;
      }
    }
    return -1;
  }

  function calculateAge(birthday) {
    var age = moment().diff(birthday, 'years');
    return age;
  }

  useEffect(() => {
    setTimeout(() => {
      const res = selectedVacTypeNonDatesNum();
      setDatesNum(res[0]);
      setOptionalShots(res[1]);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const res = selectedVacTypeNonDatesNum();
      setDatesNum(res[0]);
      setOptionalShots(res[1]);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacConfig.vacTypes]);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_uplvac_displ__');
    if (vacData.vacImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (vacData.vacImage.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(vacData.vacImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
  }, [vacData.vacImage]);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_uplvac_displ__');
    if (review && vacData.vacImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (vacData.vacImage.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(vacData.vacImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review]);

  const onDateChange = (dateNum, date) => {
    let dates = vacData.vacDates;
    if (!dates) dates = [];
    for (let _i = dates.length; _i <= dateNum; _i++) dates.push(null);
    dates[dateNum] = date;

    gs.dispatch({ type: 'set_vacDates', data: dates });
    setErr('');
  };

  const onClickVacs = (vac_id) => {
    gs.dispatch({ type: 'select_vacs', data: vac_id });
    setErr('');
  };

  const onClickSave = () => {
    setErr('');
    setErrAccount(false);

    for (let i = 0; i < datesNum; i++) {
      if (!vacData.vacDates || !vacData.vacDates[i]) {
        if (!optionalShots.includes(i)) {
          setErr(`Missing vaccination date ${datesNum > 1 ? i + 1 : ''}`);
          return;
        }
      } else {
        var todayDate = moment();
        var vDate = moment(vacData.vacDates[i]);

        if (vDate.isAfter(todayDate)) {
          setErr(
            `Vaccination date ${
              datesNum > 1 ? i + 1 : ''
            } cannot be in the future`
          );
          return;
        }
      }
    }

    if (!vacData.vacImage) {
      setErr(`Missing vaccine image`);
      return;
    }

    if (Object.keys(userData).length === 0) {
      setErr(`Missing user account info`);
      setErrAccount(true);
      return;
    }

    setSubmitting(true);

    const imgCont = document.getElementById('__vc_img_uplvac_displ__');

    const userDataObj = {};
    Object.keys(userData).forEach((k) => {
      if (!k.endsWith('_#confirm#')) userDataObj[k] = userData[k];
    });

    const imagePayload = {
      client: clientInfo.clientCode,
      event: clientInfo.clientId,
      imageType: vacData.vacImage.type,
      image: imgCont ? (imgCont.src ? imgCont.src : imgCont.data) : null,
    };

    storeImage(imagePayload)
      .then((res) => {
        if (forceFlowAll) {
          gs.dispatch({
            type: 'set_vacDataPayload',
            data: {
              vacType: vacConfig.vacTypes.find((x) => x.selected).id,
              vacDates: vacData.vacDates,
              images: [{ type: vacData.vacImage.type, id: res.data.id }],
            },
          });
          setSubmitting(false);
          props.history.push(
            `/${clientPath}${eventPath ? '/' + eventPath : ''}/addtest`
          );
          return;
        }

        const payload = {
          ...clientInfo,
          userData: userDataObj,
          vacData: {
            vacType: vacConfig.vacTypes.find((x) => x.selected).id,
            vacDates: vacData.vacDates,
            images: [{ type: vacData.vacImage.type, id: res.data.id }],
          },
        };

        createUserRec(payload)
          .then(() => {
            setSubmitting(false);
            props.history.push(
              `/${clientPath}${eventPath ? '/' + eventPath : ''}/gtg`
            );
          })
          .catch((_) => {
            setSubmitting(false);
            setErr('Failed to save user');
          });
      })
      .catch(() => {
        setSubmitting(false);
        setErr('Failed to save vaccine image');
      });
  };

  const navBack = () => {
    const nextUrl =
      testVacSelector.show && !forceFlowAll ? 'selproof' : 'regaccount';
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/${nextUrl}`
    );
  };

  const navToDateChange = () => {
    setReview(false);
    setErr('');
  };

  const vacNumTxt = (i) => {
    if (i === 0) {
      return '1st';
    } else if (i === 1) {
      return '2nd';
    } else if (i === 2) {
      return '3rd / booster';
    } else {
      return i + 1 + 'th / booster';
    }
  };

  const navToUpload = () => {
    for (let i = 0; i < datesNum; i++) {
      if (!vacData.vacDates || !vacData.vacDates[i]) {
        if (!optionalShots.includes(i)) {
          setErr(`Please enter ${vacNumTxt(i)} vaccination date`);
          return;
        }
      } else {
        var todayDate = moment();
        var vDate = moment(vacData.vacDates[i]);
        console.log(todayDate);
        if (vDate.isAfter(todayDate)) {
          setErr(
            `Vaccination date ${
              datesNum > 1 ? i + 1 : ''
            } cannot be in the future`
          );
          return;
        }
      }
    }
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploadv`
    );
    setErr('');
  };

  const navToUploadToChange = (dir) => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploadv/l`
    );
    setErr('');
  };

  const navToAccountInfo = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/regaccount/l`
    );
  };

  const selectedVacTypeNonDatesNum = () => {
    if (vacConfig && vacConfig.vacTypes) {
      const selected = vacConfig.vacTypes.find((x) => x.selected);

      const os = [...selected.optionalShots];

      const dobReg = checkDobReqs();
      if (dobReg >= 0 && os && os.length > 0) {
        if (dobReg === 0 || os.length < 2) {
          os.splice(0, 1);
        } else {
          os.splice(0, 2);
        }
      }

      return [selected.dates, os || []];
    }
    return [0, []];
  };

  const onClose = () => {
    gs.dispatch({ type: 'set_vacImage', data: null });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  return (
    <SlidePage right={dirRight}>
      {submitting && <Overlay />}
      <PageContainer>
        <HeaderFlow
          label={review ? 'Confirm Vaccine Submission' : 'Vaccine Information'}
          onLeave={review ? false : navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        {review ? (
          <VacContainer>
            {err && (
              <div>
                <Error style={{ margin: '20px 0 20px 0' }}>{err}</Error>
                {errAccount && (
                  <Link
                    onClick={navToAccountInfo}
                    style={{ margin: '-15px 0 0 0' }}
                  >
                    Return to Account Information
                  </Link>
                )}
              </div>
            )}
            <Link style={{ margin: '30px 0 10px 0' }} onClick={navToDateChange}>
              Change Vaccine Type or Dates
            </Link>
            <OptionContainer>
              {vacConfig.vacTypes
                .filter((x) => x.selected)
                .map((s) => (
                  <Option key={s.id}>
                    <Text5
                      style={{
                        color: 'var(--color-light-1)',
                        lineHeight: '2.1rem',
                      }}
                    >
                      {s.name}
                    </Text5>
                    <span>
                      <i
                        className='far fa-dot-circle'
                        style={{
                          height: '20px',
                          width: '20px',
                          color: 'var(--color-light-1)',
                        }}
                      ></i>
                    </span>
                  </Option>
                ))}
            </OptionContainer>
            {[...Array(datesNum).keys()].map((i) => (
              <Fragment key={i}>
                <Text6 style={{ marginBottom: '5px' }}>
                  Date of {vacNumTxt(i)}
                  {'  vaccination'}
                  {optionalShots.includes(i) && ' (optional)'}
                </Text6>
                <DateInput
                  type='date'
                  value={(vacData.vacDates && vacData.vacDates[i]) || ''}
                  onChange={(e) => onDateChange(i, e.target.value)}
                  style={{ marginBottom: '25px' }}
                  readOnly
                ></DateInput>
              </Fragment>
            ))}
            <Link
              style={{ margin: '20px 0 5px 0' }}
              onClick={navToUploadToChange}
            >
              Upload another image
            </Link>
            {vacData.vacImage &&
              vacData.vacImage.type === 'application/pdf' && (
                <PdfPreview
                  id='__vc_img_uplvac_displ__'
                  style={{ opacity: vacData.vacImage ? 1 : 0 }}
                />
              )}
            {vacData.vacImage &&
              vacData.vacImage.type !== 'application/pdf' && (
                <ImgPreview
                  id='__vc_img_uplvac_displ__'
                  style={{ opacity: vacData.vacImage ? 1 : 0 }}
                />
              )}
          </VacContainer>
        ) : (
          <VacContainer>
            <Text3 style={{ margin: '10px 0 25px 0' }}>
              What vaccine did you receive?
            </Text3>
            <OptionContainer
              style={
                vacConfig &&
                vacConfig.vacTypes &&
                vacConfig.vacTypes.length &&
                vacConfig.vacTypes.length > 5
                  ? { gridTemplateColumns: '1fr 1fr', gridGap: '20px 10px' }
                  : { gridTemplateColumns: '1fr', gridGap: '20px 0px' }
              }
            >
              {vacConfig &&
                vacConfig.vacTypes &&
                vacConfig.vacTypes.map((s) => (
                  <Option key={s.id} onClick={() => onClickVacs(s.id)}>
                    <Text5
                      style={{
                        color: 'var(--color-light-1)',
                        fontSize:
                          vacConfig.vacTypes.length > 5 ? '1.5rem' : '1.8rem',
                        lineHeight: '2rem',
                      }}
                    >
                      {s.name}
                    </Text5>
                    {s.selected && (
                      <span>
                        <i
                          className='far fa-dot-circle'
                          style={{
                            height: '20px',
                            width: '20px',
                            color: 'var(--color-light-1)',
                          }}
                        ></i>
                      </span>
                    )}
                    {!s.selected && (
                      <span>
                        <i
                          className='far fa-circle'
                          style={{
                            height: '20px',
                            width: '20px',
                            color: 'var(--color-light-1)',
                          }}
                        ></i>
                      </span>
                    )}
                  </Option>
                ))}
            </OptionContainer>
            {err && <Error style={{ margin: '5px 0 15px 0' }}>{err}</Error>}
            {[...Array(datesNum).keys()].map((i) => (
              <Fragment key={i}>
                <Text6 style={{ marginBottom: '5px' }}>
                  Date of {vacNumTxt(i)}
                  {'  vaccination'}
                  {optionalShots.includes(i) && ' (optional)'}
                </Text6>
                <DateInput
                  type='date'
                  value={(vacData.vacDates && vacData.vacDates[i]) || ''}
                  onChange={(e) => onDateChange(i, e.target.value)}
                  style={{ marginBottom: '25px' }}
                ></DateInput>
              </Fragment>
            ))}
            {vacData.vacImage &&
              vacData.vacImage.type === 'application/pdf' && (
                <PdfPreview
                  id='__vc_img_uplvac_displ__'
                  style={{ opacity: vacData.vacImage ? 1 : 0 }}
                />
              )}
            {vacData.vacImage &&
              vacData.vacImage.type !== 'application/pdf' && (
                <ImgPreview
                  id='__vc_img_uplvac_displ__'
                  style={{ opacity: vacData.vacImage ? 1 : 0 }}
                />
              )}
          </VacContainer>
        )}
        <Button onClick={review ? onClickSave : navToUpload}>
          {review
            ? 'Submit Vaccination Information'
            : 'Continue to Upload Vaccine Records'}
        </Button>
      </PageContainer>
    </SlidePage>
  );
};

export { AddVaccine };
