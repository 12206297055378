import React, { createContext, useReducer } from 'react';

const appState = {
  clientInfo: undefined,
  landingInfo: undefined,
  userDataConfig: undefined,
  acceptedTerms: false,
  testVacSelector: {},
  testConfig: {},
  vacConfig: {},
  exempConfig: {},
  userData: {},
  exempData: {},
  testData: {},
  vacData: {},
  inited: false,
  vacDataPayload: {},
  forceFlowAll: false,
};

const store = createContext(appState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'set_client_info':
        return { ...state, clientInfo: action.data };
      case 'set_landing_info':
        return { ...state, landingInfo: action.data };
      case 'set_userdata_config':
        return { ...state, userDataConfig: action.data };
      case 'set_testVacSelector':
        return { ...state, testVacSelector: action.data };
      case 'set_test_config':
        return { ...state, testConfig: action.data };
      case 'set_exemp_config':
        return { ...state, exempConfig: action.data };
      case 'set_vac_config':
        const vacTypes = action.data.vacTypes;
        return {
          ...state,
          vacConfig: {
            ...action.data,
            vacTypes: vacTypes.map((x, idx) => ({
              ...x,
              selected: idx === 0 ? true : false,
            })),
          },
        };
      case 'select_vacs':
        return {
          ...state,
          vacConfig: {
            ...state.vacConfig,
            vacTypes: state.vacConfig.vacTypes.map((x) => ({
              ...x,
              selected: x.id === action.data,
            })),
          },
        };
      case 'set_userdata':
        return { ...state, userData: { ...state.userData, ...action.data } };
      case 'set_testDate':
        return {
          ...state,
          testData: { ...state.testData, testDate: action.data },
        };
      case 'set_testImage':
        return {
          ...state,
          testData: { ...state.testData, testImage: action.data },
        };
      case 'set_exempDate':
        return {
          ...state,
          exempData: { ...state.exempData, exempDate: action.data },
        };
      case 'set_exempImage':
        return {
          ...state,
          exempData: { ...state.exempData, exempImage: action.data },
        };
      case 'set_vacDates':
        return {
          ...state,
          vacData: { ...state.vacData, vacDates: action.data },
        };
      case 'set_vacDataPayload':
        return {
          ...state,
          vacDataPayload: { ...action.data },
        };
      case 'set_vacImage':
        return {
          ...state,
          vacData: { ...state.vacData, vacImage: action.data },
        };
      case 'accept_terms':
        return { ...state, acceptedTerms: action.data };
      case 'reset_data':
        return {
          ...state,
          userData: {},
          vacData: {},
          testData: {},
          acceptedTerms: false,
          forceFlowAll: false,
        };
      case 'set_inited':
        return {
          ...state,
          inited: true,
        };
      case 'set_forceFlowAll':
        return { ...state, forceFlowAll: action.data };

      default:
        throw new Error();
    }
  }, appState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
