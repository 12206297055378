import React, { useState, useEffect, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import ImagePreview from './ImagePreview';
import styled from 'styled-components';
import {
  getImage,
  updateUserRec,
  sendVerifEmail,
  deleteUserRec,
} from '../../api';
import { imageCDN } from '../../config';
import { Overlay, Input } from '..';

const PageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 20px 1fr auto;
  grid-template-columns: auto;
  justify-content: start;
  align-content: start;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--color-light-1);
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  z-index: 101;
  overflow: hidden;
`;

const DeleteContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 250px;
  justify-content: start;
  grid-gap: 0 10px;
  padding: 0 25px;
  margin-bottom: 20px;
`;

const OverlayBox = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-gap: 30px;
  padding: 20px 25px 10px 25px;
  width: 100%;
  box-sizing: border-box;

  & > span {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 19px;
    color: var(--color-dark-1);
    user-select: none;
  }

  & > img {
    cursor: pointer;
  }
`;

const Msg = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-link);
  user-select: none;
  margin: 5px 0 0 25px;
`;

const Err = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-err-1);
  user-select: none;
  margin: 5px 0 0 25px;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px 25px;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: 1fr 20px;
  grid-gap: 20px 0;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
`;

const UserTable = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  align-content: start;
  grid-template-columns: 90px 220px 110px 180px 300px 130px 130px 50px;
  grid-gap: 5px 25px;
  box-sizing: border-box;
  border: 2px solid var(--color-dark-3);
  border-radius: 10px;
  padding: 20px 20px;
  height: 190px;
`;

const UserTableColHdr = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-dark-1);
  user-select: none;
`;

const UserTableCell = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-dark-1);
  user-select: none;
`;

const UserTableText = styled.input`
  font-size: 1.6rem;
  font-weight: normal;
  height: 30px;
  color: var(--color-dark-1);
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
`;

const TestTableContainer = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  border: 2px solid var(--color-dark-3);
  border-radius: 10px;
  padding: 20px 15px;
  width: auto;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const TestTableHdr = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  align-content: start;
  grid-template-columns: 200px 25px 150px 25px 60px 25px;
  grid-gap: 15px 0;
  width: 100%;
  box-sizing: border-box;
`;

const TestTableColHdr = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-dark-1);
  user-select: none;
  border-bottom: 2px solid var(--color-dark-3);
  padding-bottom: 20px;
`;

const TestTable = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  align-content: start;
  grid-template-columns: 200px 25px 150px 25px 60px 25px;
  grid-gap: 0 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TestTableCell = styled.span`
  font-size: 1.6rem;
  user-select: none;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid var(--color-dark-3);
`;

const VacTableContainer = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  border: 2px solid var(--color-dark-3);
  border-radius: 10px;
  padding: 20px 15px;
  width: auto;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const VacTableHdr = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  align-content: start;
  grid-template-columns: 200px 25px 200px 25px 200px 25px 200px 25px 200px 25px 60px 25px;
  grid-gap: 15px 0;
  width: 100%;
  box-sizing: border-box;
`;

const VacTableColHdr = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-dark-1);
  user-select: none;
  border-bottom: 2px solid var(--color-dark-3);
  padding-bottom: 20px;
`;

const VacTable = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  align-content: start;
  grid-template-columns: 200px 25px 200px 25px 200px 25px 200px 25px 200px 25px 60px 25px;
  grid-gap: 0 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
`;

const VacTableCell = styled.span`
  font-size: 1.6rem;
  user-select: none;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid var(--color-dark-3);
`;

const Gap = styled.div`
  border-bottom: 2px solid var(--color-dark-3);
`;

const GapC = styled.div`
  border-bottom: 1px solid var(--color-dark-3);
`;

const ImgPreview = styled.img`
  position: relative;
  width: 100%;
  height: 50px;
  cursor: pointer;
`;

const DateFilter = styled.input`
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.1rem;
  height: 30px;
  color: var(--color-dark-1);
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: normal;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  margin-top: 10px;
  color: var(--color-light-1);
  background-color: var(--color-link);
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
  cursor: pointer;
`;

const Button2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: normal;
  height: 30px;
  width: 200px;
  padding: 0 10px;
  margin-top: 10px;
  color: var(--color-light-1);
  background-color: var(--color-link);
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
  cursor: pointer;
`;

const SelectFilter = styled.select`
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.1rem;
  height: 30px;
  color: var(--color-dark-1);
  width: 100%;
  box-sizing: border-box;
`;

function getAge(dob) {
  if (dob) {
    var age = moment().diff(dob, 'years');
    return age;
  }
  return '';
}

const Image = (props) => {
  const id = uuidv4();
  const { image, type, onClick } = props;
  useEffect(() => {
    const imgCont = document.getElementById(id);
    if (type === 'application/pdf') {
      imgCont.src = `${imageCDN}pdf.svg`;
    } else if (image && imgCont) {
      imgCont.src = image;
    }
  }, [id, image, type]);

  return image ? (
    <ImgPreview id={id} style={{ gridArea: 'imgp' }} onClick={onClick} />
  ) : null;
};

const formatPhone = (val) => {
  const p1 = val.slice(0, 3);
  const p2 = val.slice(3, 6);
  const p3 = val.slice(6);
  return `${p1}${p2.length > 0 ? '-' : ''}${p2}${
    p3.length > 0 ? '-' : ''
  }${p3}`;
};

const UserDetail = (props) => {
  const {
    user,
    role,
    closeDetail,
    statusChange,
    noteChange,
    vaccineChange,
    testDateChange,
    exempDateChange,
    deleteRec,
    event,
  } = props;

  const [test, setTest] = useState(null);
  const [testDate, setTestDate] = useState(null);
  const [testImage, setTestImage] = useState(null);
  const [testImageType, setTestImageType] = useState(null);
  const [exemp, setExemp] = useState(null);
  const [exempDate, setExempDate] = useState(null);
  const [exempImage, setExempImage] = useState(null);
  const [exempImageType, setExempImageType] = useState(null);
  const [vac, setVac] = useState(null);
  const [vacDate1, setVacDate1] = useState(null);
  const [vacDate2, setVacDate2] = useState(null);
  const [vacDate3, setVacDate3] = useState(null);
  const [vacDate4, setVacDate4] = useState(null);
  const [vacTypeId, setVacTypeId] = useState(null);
  const [vacImage, setVacImage] = useState('');
  const [vacImageType, setVacImageType] = useState('');
  const [imgOpen, setImgOpen] = useState(null);
  const [vacPending, setVacPending] = useState('');
  const [testPending, setTestPending] = useState('');
  const [exempPending, setExempPending] = useState('');
  const [saveTest, setSaveTest] = useState('');
  const [saveVac, setSaveVac] = useState('');
  const [saveExemp, setSaveExemp] = useState('');
  const [vacTypes, setVacTypes] = useState([]);
  const [origStatus, setOrigStatus] = useState('');
  const [status, setStatus] = useState('');
  const [origNote, setOrigNote] = useState('');
  const [note, setNote] = useState('');
  const [noteSaveButton, setNoteSaveButton] = useState(false);
  const [pendingOp, setPendingOp] = useState(false);
  const [msg, setMsg] = useState('');
  const [err, setErr] = useState('');
  const [delState, setDelState] = useState('');
  const [delConf, setDelConf] = useState('');

  useEffect(() => {
    setOrigStatus(user.status || '');
    setStatus(user.status || '');
    setOrigNote(user.note || '');
    setNote(user.note || '');
    setVacTypes(event.vacTypes || []);

    setTestPending('Reading test data ...');
    if (user.testData) {
      const test = user.testData;
      setTest(test);
      setTestDate(test.testDate);
      if (test.images.length > 0) {
        setTestImageType(test.images[0].type);
        getImage(event.clientCode, event.event, test.images[0].id)
          .then((res) => {
            setTestImage(res.data.image);
          })
          .catch((err) => {
            const { status } = err.response;
            switch (status) {
              case 401:
                setErr('Not Authorized');
                break;
              default:
                setErr('Failed to get test data');
                break;
            }
          })
          .finally(() => setTestPending(''));
      } else {
        setTestPending('');
      }
    } else {
      setTestPending('');
    }

    setVacPending('Reading vaccine data ...');
    if (user.vacData) {
      const vac = user.vacData;
      setVac(vac);
      setVacDate1(vac.vacDates[0]);
      vac.vacDates.length > 1 ? setVacDate2(vac.vacDates[1]) : setVacDate2('');
      vac.vacDates.length > 2 ? setVacDate3(vac.vacDates[2]) : setVacDate3('');
      vac.vacDates.length > 3 ? setVacDate4(vac.vacDates[3]) : setVacDate4('');
      setVacTypeId(vac.vacType);
      if (vac.images.length > 0) {
        setVacImageType(vac.images[0].type);

        getImage(event.clientCode, event.event, vac.images[0].id)
          .then((res) => {
            setVacImage(res.data.image);
          })
          .catch((err) => {
            const { status } = err.response;
            switch (status) {
              case 401:
                setErr('Not Authorized');
                break;
              default:
                setErr('Failed to get vaccine data');
                break;
            }
          })
          .finally(() => setVacPending(''));
      } else {
        setVacPending('');
      }
    } else {
      setVacPending('');
    }

    setExempPending('Reading exemption data ...');
    if (user.exempData) {
      const exemp = user.exempData;
      setExemp(exemp);
      setExempDate(exemp.exempDate);
      if (exemp.images.length > 0) {
        setExempImageType(exemp.images[0].type);
        getImage(event.clientCode, event.event, exemp.images[0].id)
          .then((res) => {
            setExempImage(res.data.image);
          })
          .catch((err) => {
            const { status } = err.response;
            switch (status) {
              case 401:
                setErr('Not Authorized');
                break;
              default:
                setErr('Failed to get exemption data');
                break;
            }
          })
          .finally(() => setExempPending(''));
      } else {
        setExempPending('');
      }
    } else {
      setExempPending('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickContainer = (e) => {
    e.stopPropagation();
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
    setMsg('');
    setErr('');

    if (e.target.value !== origStatus) {
      setPendingOp(true);
      updateUserRec({
        client: event.clientCode,
        event: event.event,
        id: user.id,
        status: e.target.value,
      })
        .then((res) => {
          statusChange(
            user.id,
            e.target.value,
            res.data.verifiedBy,
            res.data.verifiedOn
          );
          setOrigStatus(e.target.value);
          setMsg('Saved status');
        })
        .catch((err) => {
          const { status } = err.response;
          switch (status) {
            case 401:
              setErr('Not Authorized');
              break;
            default:
              setErr('Failed to save status');
              break;
          }
        })
        .finally(() => setPendingOp(false));
    }
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
    setMsg('');
    setErr('');

    if (e.target.value !== origNote) {
      setNoteSaveButton(true);
    } else {
      setNoteSaveButton(false);
    }
  };

  const onSaveNote = () => {
    setPendingOp(true);
    updateUserRec({
      client: event.clientCode,
      event: event.event,
      id: user.id,
      note,
    })
      .then(() => {
        noteChange(user.id, note);
        setOrigNote(note);
        setMsg('Saved note');
      })
      .catch((err) => {
        const { status } = err.response;
        switch (status) {
          case 401:
            setErr('Not Authorized');
            break;
          default:
            setErr('Failed to save note');
            break;
        }
      })
      .finally(() => {
        setPendingOp(false);
        setNoteSaveButton(false);
      });
  };

  const onChangeTestDate = (e) => {
    if (e.target.value) {
      setTestDate(e.target.value);
      setSaveTest('Save');
    }
  };

  const onChangeExempDate = (e) => {
    if (e.target.value) {
      setExempDate(e.target.value);
      setSaveExemp('Save');
    }
  };

  const onSaveTest = () => {
    setPendingOp(true);
    updateUserRec({
      client: event.clientCode,
      event: event.event,
      id: user.id,
      testDate: testDate,
    })
      .then(() => {
        setMsg('Saved test info');
        testDateChange(user.id, testDate);
      })
      .catch((err) => {
        const { status } = err.response;
        switch (status) {
          case 401:
            setErr('Not Authorized');
            break;
          default:
            setErr('Failed to save test info');
            break;
        }
      })
      .finally(() => {
        setPendingOp(false);
        setSaveTest('');
      });
  };

  const onChangeVacDate1 = (e) => {
    if (e.target.value) {
      setVacDate1(e.target.value);
      setSaveVac('Save');
    }
  };

  const onChangeVacDate2 = (e) => {
    if (e.target.value) {
      setVacDate2(e.target.value);
      setSaveVac('Save');
    }
  };

  const onChangeVacDate3 = (e) => {
    if (e.target.value) {
      setVacDate3(e.target.value);
      setSaveVac('Save');
    }
  };

  const onChangeVacDate4 = (e) => {
    if (e.target.value) {
      setVacDate4(e.target.value);
      setSaveVac('Save');
    }
  };

  const onChangeVacType = (e) => {
    if (e.target.value) {
      setVacTypeId(e.target.value);
      setSaveVac('Save');
    }
  };

  const onSaveVac = () => {
    setPendingOp(true);

    const vac = vacTypes.find((x) => x.id === parseInt(vacTypeId));
    const vacDates = [vacDate1];
    if (vac.dates > 1) vacDates.push(vacDate2);
    if (vac.dates > 2) vacDates.push(vacDate3);
    if (vac.dates > 3) vacDates.push(vacDate4);

    updateUserRec({
      client: event.clientCode,
      event: event.event,
      id: user.id,
      vacType: parseInt(vacTypeId),
      vacDates,
    })
      .then(() => {
        setMsg('Saved vaccine info');
        vaccineChange(user.id, parseInt(vacTypeId), vacDates);
      })
      .catch((err) => {
        const { status } = err.response;
        switch (status) {
          case 401:
            setErr('Not Authorized');
            break;
          default:
            setErr('Failed to save vaccine info');
            break;
        }
      })
      .finally(() => {
        setPendingOp(false);
        setSaveTest('');
      });
  };

  const onSaveExemp = () => {
    setPendingOp(true);
    updateUserRec({
      client: event.clientCode,
      event: event.event,
      id: user.id,
      exempDate: exempDate,
    })
      .then(() => {
        setMsg('Saved exemption info');
        exempDateChange(user.id, exempDate);
      })
      .catch((err) => {
        const { status } = err.response;
        switch (status) {
          case 401:
            setErr('Not Authorized');
            break;
          default:
            setErr('Failed to save exemption info');
            break;
        }
      })
      .finally(() => {
        setPendingOp(false);
        setSaveExemp('');
      });
  };

  const onSendVerifEmail = () => {
    setPendingOp(true);
    sendVerifEmail(event.clientCode, event.event, user.id)
      .then(() => {
        setMsg('Sent Email');
      })
      .catch((err) => {
        const { status } = err.response;
        switch (status) {
          case 401:
            setErr('Not Authorized');
            break;
          default:
            setErr('Failed to send email');
            break;
        }
      })
      .finally(() => {
        setPendingOp(false);
      });
  };

  const vacShotsNum = (vacId) => {
    const vac = vacTypes.find((x) => x.id === parseInt(vacId));
    if (vac && vac.dates) {
      return vac.dates;
    }
    return 3;
  };

  const onDelete = () => {
    if (delState === '') {
      setDelState('confirm');
    } else {
      if (delConf.toLowerCase() === 'yes') {
        const client = event.clientCode;
        const eventCode = user.eventCode;
        const id = user.id;
        const payload = {
          client,
          event: eventCode,
          id,
        };

        setPendingOp(true);

        deleteUserRec(payload)
          .then(() => {
            setPendingOp(false);
            deleteRec();
            closeDetail();
          })
          .catch((err) => {
            setPendingOp(false);
            const { status } = err.response;
            switch (status) {
              case 401:
                setErr('Not Authorized');
                break;
              default:
                setErr('Failed to delete record');
                break;
            }
          });
      }
    }
  };

  const onDelConfChange = (e) => {
    setDelConf(e.target.value);
  };

  return (
    <PageContainer onClick={onClickContainer}>
      {pendingOp && (
        <OverlayBox>
          <Overlay />
        </OverlayBox>
      )}
      {imgOpen && (
        <ImagePreview
          image={imgOpen.image}
          type={imgOpen.type}
          onClose={() => setImgOpen(null)}
        />
      )}
      <Header>
        <img src={`${imageCDN}times.png`} alt='close' onClick={closeDetail} />
        <span>Admin Dashboard</span>
      </Header>
      {msg ? <Msg>{msg}</Msg> : err ? <Err>{err}</Err> : <span />}
      <Container>
        <UserTable>
          <UserTableColHdr>Status</UserTableColHdr>
          <UserTableColHdr>Note</UserTableColHdr>
          <UserTableColHdr>First Name</UserTableColHdr>
          <UserTableColHdr>Last Name</UserTableColHdr>
          <UserTableColHdr>Email</UserTableColHdr>
          <UserTableColHdr>Phone</UserTableColHdr>
          <UserTableColHdr>DOB</UserTableColHdr>
          <UserTableColHdr>Age</UserTableColHdr>
          <UserTableCell>
            <SelectFilter value={status} onChange={onStatusChange}>
              <option></option>
              <option>Verified</option>
              <option>Rejected</option>
            </SelectFilter>
          </UserTableCell>
          <UserTableCell>
            <UserTableText
              defaultValue={user.note || ''}
              onChange={onNoteChange}
            />
          </UserTableCell>
          <UserTableCell>
            <UserTableText defaultValue={user.userData.firstName} readOnly />
          </UserTableCell>
          <UserTableCell>
            <UserTableText defaultValue={user.userData.lastName} readOnly />
          </UserTableCell>
          <UserTableCell>
            <UserTableText defaultValue={user.userData.email} readOnly />
          </UserTableCell>
          <UserTableCell>
            <UserTableText
              defaultValue={
                user.userData.phone.startsWith('+')
                  ? user.userData.phone
                  : formatPhone(user.userData.phone)
              }
              readOnly
            />
          </UserTableCell>
          <UserTableCell>
            <UserTableText defaultValue={user.userData.dob || ''} readOnly />
          </UserTableCell>
          <UserTableCell>
            <UserTableText defaultValue={getAge(user.userData.dob)} readOnly />
          </UserTableCell>
          {status === 'Verified' && (
            <>
              <Button2 onClick={onSendVerifEmail}>
                <span>Send Verification Email</span>
              </Button2>
              <span />
              <span />
              <span />
              <span />
              <span />
            </>
          )}
          {noteSaveButton && (
            <>
              <Button onClick={onSaveNote}>
                <span>Save Note</span>
              </Button>
              <span />
              <span />
              <span />
              <span />
              <span />
            </>
          )}
        </UserTable>
        <div></div>
        <TestTableContainer>
          <TestTableHdr>
            <TestTableColHdr>Test Type</TestTableColHdr>
            <Gap />
            <TestTableColHdr>Test Date</TestTableColHdr>
            <Gap />
            <TestTableColHdr>Image</TestTableColHdr>
            <Gap />
          </TestTableHdr>
          <TestTable>
            {testPending ? (
              <VacTableCell>{testPending}</VacTableCell>
            ) : test ? (
              <Fragment>
                <TestTableCell></TestTableCell>
                <GapC />
                <TestTableCell>
                  <DateFilter
                    type='date'
                    value={testDate}
                    onChange={onChangeTestDate}
                  />
                </TestTableCell>
                <GapC />
                <TestTableCell>
                  {testImage ? (
                    <Image
                      image={testImage}
                      type={testImageType}
                      onClick={() =>
                        setImgOpen({ image: testImage, type: testImageType })
                      }
                    ></Image>
                  ) : null}
                </TestTableCell>
                <GapC />
              </Fragment>
            ) : (
              <VacTableCell>No Test Data</VacTableCell>
            )}
          </TestTable>
          {saveTest && (
            <Button onClick={onSaveTest}>
              <span>{saveTest}</span>
            </Button>
          )}
        </TestTableContainer>
        <div></div>
        <VacTableContainer>
          <VacTableHdr>
            <VacTableColHdr>Vaccine Type</VacTableColHdr>
            <Gap />
            <VacTableColHdr>1st Shot Date</VacTableColHdr>
            <Gap />
            <VacTableColHdr>2nd Shot Date</VacTableColHdr>
            <Gap />
            <VacTableColHdr>3rd Shot Date</VacTableColHdr>
            <Gap />
            <VacTableColHdr>4rd Shot Date</VacTableColHdr>
            <Gap />
            <VacTableColHdr>Image</VacTableColHdr>
            <Gap />
          </VacTableHdr>
          <VacTable>
            {vacPending ? (
              <VacTableCell>{vacPending}</VacTableCell>
            ) : vac ? (
              <Fragment>
                <VacTableCell>
                  <SelectFilter
                    value={vacTypeId || ''}
                    onChange={onChangeVacType}
                  >
                    {vacTypes.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </SelectFilter>
                </VacTableCell>
                <GapC />
                <VacTableCell>
                  {vacDate1 ? (
                    <DateFilter
                      type='date'
                      value={vacDate1}
                      onChange={onChangeVacDate1}
                    />
                  ) : (
                    ''
                  )}
                </VacTableCell>
                <GapC />
                <VacTableCell>
                  {vacShotsNum(vacTypeId) > 1 ? (
                    <DateFilter
                      type='date'
                      value={vacDate2 || ''}
                      onChange={onChangeVacDate2}
                    />
                  ) : (
                    ''
                  )}
                </VacTableCell>
                <GapC />
                <VacTableCell>
                  {vacShotsNum(vacTypeId) > 2 ? (
                    <DateFilter
                      type='date'
                      value={vacDate3 || ''}
                      onChange={onChangeVacDate3}
                    />
                  ) : (
                    ''
                  )}
                </VacTableCell>
                <GapC />
                <VacTableCell>
                  {vacShotsNum(vacTypeId) > 3 ? (
                    <DateFilter
                      type='date'
                      value={vacDate4 || ''}
                      onChange={onChangeVacDate4}
                    />
                  ) : (
                    ''
                  )}
                </VacTableCell>
                <GapC />
                <VacTableCell>
                  <Image
                    image={vacImage}
                    type={vacImageType}
                    onClick={() =>
                      setImgOpen({ image: vacImage, type: vacImageType })
                    }
                  ></Image>
                </VacTableCell>
                <GapC />
              </Fragment>
            ) : (
              <>
                <VacTableCell>
                  No Vaccine Data
                  <br />
                </VacTableCell>
              </>
            )}
          </VacTable>
          {saveVac && (
            <Button onClick={onSaveVac}>
              <span>{saveVac}</span>
            </Button>
          )}
        </VacTableContainer>
        <div></div>
        <TestTableContainer>
          <TestTableHdr>
            <TestTableColHdr>Exemption</TestTableColHdr>
            <Gap />
            <TestTableColHdr>Exemption Date</TestTableColHdr>
            <Gap />
            <TestTableColHdr>Image</TestTableColHdr>
            <Gap />
          </TestTableHdr>
          <TestTable>
            {exempPending ? (
              <VacTableCell>{exempPending}</VacTableCell>
            ) : exemp ? (
              <Fragment>
                <TestTableCell></TestTableCell>
                <GapC />
                <TestTableCell>
                  <DateFilter
                    type='date'
                    value={exempDate}
                    onChange={onChangeExempDate}
                  />
                </TestTableCell>
                <GapC />
                <TestTableCell>
                  {exempImage ? (
                    <Image
                      image={exempImage}
                      type={exempImageType}
                      onClick={() =>
                        setImgOpen({ image: exempImage, type: exempImageType })
                      }
                    ></Image>
                  ) : null}
                </TestTableCell>
                <GapC />
              </Fragment>
            ) : (
              <VacTableCell>No Exemption Data</VacTableCell>
            )}
          </TestTable>
          {saveExemp && (
            <Button onClick={onSaveExemp}>
              <span>{saveExemp}</span>
            </Button>
          )}
        </TestTableContainer>
        <div></div>
      </Container>
      {role === 'superadmin' && (
        <DeleteContainer>
          <Button2 onClick={onDelete}>
            <span>
              {delState !== 'confirm'
                ? 'Delete'
                : delConf.toLowerCase() === 'yes'
                ? 'Confirm Delete'
                : 'Delete'}
            </span>
          </Button2>
          {delState === 'confirm' && (
            <Input
              style={{ marginTop: '10px' }}
              placeholder="Type 'yes' to confirm delete"
              value={delConf}
              onChange={onDelConfChange}
            />
          )}
        </DeleteContainer>
      )}
    </PageContainer>
  );
};

export { UserDetail };
