import React, { useContext } from 'react';
import styled from 'styled-components';
import { HeaderFlow, SlidePage, Text4, Text7 } from '..';
import { store } from '../../store.js';
import { formatText } from '../../utils';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const ContentContainer = styled.div`
  overflow: auto;
  padding: 0 0;
  box-sizing: border-box;
`;

const NavButton = styled.div`
  height: 120px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0 18px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  margin: 25px 0;
  color: var(--color-light-1);
  font-weight: 600;
  font-size: 2rem;
  padding: 0 5px 0 10px;
  box-sizing: border-box;
  line-height: 2.7rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 430px) {
    grid-gap: 0 12px;
  }

  @media screen and (max-width: 350px) {
    grid-template-columns: auto 1fr;

    & > svg:first-child {
      display: none;
    }
  }
`;

const NoteText = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
`;

const SelectProof = (props) => {
  const gs = useContext(store);
  const { testVacSelector, testConfig, vacConfig, exempConfig, clientInfo } =
    gs.state;

  const dirRight = props.match.params.dir === 'l' ? false : true;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;

  const navBack = () => {
    const basePath = props.location.pathname.endsWith('/l')
      ? `/${clientPath}/${eventPath}/`
      : '';
    props.history.push(`${basePath}regaccount/l`);
  };

  const onClose = () => {
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  const navigateToAddTest = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/addtest`
    );
  };

  const navigateToAddVac = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/addvac`
    );
  };

  const navigateToAddExemption = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/addexemp`
    );
  };

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        <HeaderFlow
          label='Select Proof'
          onLeave={navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        <ContentContainer>
          {testVacSelector && (
            <>
              <Text4 style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}>
                {testVacSelector.title &&
                  testVacSelector.title
                    .replace('#event#', clientInfo.title || '')
                    .replace('#dates#', clientInfo.textDates || '')
                    .split('#-#')
                    .map((l, idx) => (
                      <p
                        key={idx}
                        style={{ marginTop: '25px', whiteSpace: 'pre-line' }}
                      >
                        {formatText(l).map((f) => f)}
                      </p>
                    ))}
              </Text4>
              {vacConfig.show && (
                <NavButton
                  onClick={navigateToAddVac}
                  style={{ backgroundColor: 'var(--color-nav-2' }}
                >
                  <i
                    className='far fa-syringe'
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                  ></i>
                  <span>{vacConfig.buttonLabel}</span>
                  <i
                    className='far fa-chevron-right'
                    style={{
                      height: '25px',
                      width: '25px',
                    }}
                  ></i>
                </NavButton>
              )}
              {exempConfig.show && (
                <NavButton
                  onClick={navigateToAddExemption}
                  style={{ backgroundColor: 'var(--color-nav-2' }}
                >
                  <i
                    className='far fa-vial'
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                  ></i>
                  <span>{exempConfig.buttonLabel}</span>
                  <i
                    className='far fa-chevron-right'
                    style={{
                      height: '25px',
                      width: '25px',
                    }}
                  ></i>
                </NavButton>
              )}
              {testConfig.show && (
                <NavButton
                  onClick={navigateToAddTest}
                  style={{ backgroundColor: 'var(--color-nav-2' }}
                >
                  <i
                    className='far fa-vial'
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                  ></i>
                  <span>{testConfig.buttonLabel}</span>
                  <i
                    className='far fa-chevron-right'
                    style={{
                      height: '25px',
                      width: '25px',
                    }}
                  ></i>
                </NavButton>
              )}
              <NoteText>
                {testVacSelector.info &&
                  testVacSelector.info.split('#-#').map((l, idx) => (
                    <Text7
                      key={idx}
                      style={{
                        marginBottom: '15px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {formatText(l).map((f) => f)}
                    </Text7>
                  ))}
              </NoteText>
            </>
          )}
        </ContentContainer>
      </PageContainer>
    </SlidePage>
  );
};

export { SelectProof };
