import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import {
  SlidePage,
  HeaderFlow,
  Link,
  DateInput,
  Error,
  Text3,
  Text6,
  Text7,
  Button,
  ButtonInactive,
  Overlay,
} from '..';
import { createUserRec, storeImage } from '../../api';
import { imageCDN } from '../../config';
import { formatText } from '../../utils';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 1fr;
`;

const ExempContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ImgPreview = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

const CertifyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 15px;
  cursor: pointer;
  margin: 10px 0;
`;

const Image = styled.img``;

const AddExemp = (props) => {
  const gs = useContext(store);
  const { testVacSelector, exempConfig, exempData, userData, clientInfo } =
    gs.state;

  const dirRight = props.match.params.dir === 'l' ? false : true;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;

  const [err, setErr] = useState('');
  const [review, setReview] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errAccount, setErrAccount] = useState(false);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_uplexemp_displ__');
    if (exempData.exempImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        imgCont.src = e.target.result;
      };
      imgReader.readAsDataURL(exempData.exempImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exempData.exempImage]);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_uplexemp_displ__');
    if (review && exempData.exempImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        imgCont.src = e.target.result;
      };
      imgReader.readAsDataURL(exempData.exempImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review]);

  const onDateChange = (date) => {
    gs.dispatch({ type: 'set_exempDate', data: date });
    setErr('');
  };

  const onClickSave = () => {
    setErr('');
    setErrAccount(false);

    if (!exempData.exempDate) {
      setErr(`Missing exemption date`);
      return;
    }

    if (!exempData.exempImage) {
      setErr(`Missing exemption image`);
      return;
    }

    if (Object.keys(userData).length === 0) {
      setErr(`Missing user account info`);
      setErrAccount(true);
      return;
    }

    setSubmitting(true);

    const imgCont = document.getElementById('__vc_img_uplexemp_displ__');

    const userDataObj = {};
    Object.keys(userData).forEach((k) => {
      if (!k.endsWith('_#confirm#')) userDataObj[k] = userData[k];
    });

    const imagePayload = {
      client: clientInfo.clientCode,
      event: clientInfo.clientId,
      imageType: exempData.exempImage.type,
      image: imgCont && imgCont.src,
    };

    storeImage(imagePayload)
      .then((res) => {
        let payload = {
          ...clientInfo,
          userData: userDataObj,
          exempData: {
            exempDate: exempData.exempDate,
            images: [{ type: exempData.exempImage.type, id: res.data.id }],
          },
        };

        createUserRec(payload)
          .then(() => {
            setSubmitting(false);
            props.history.push(
              `/${clientPath}${eventPath ? '/' + eventPath : ''}/gtg`
            );
          })
          .catch((_) => {
            setSubmitting(false);
            setErr('Failed to save user');
          });
      })
      .catch(() => {
        setSubmitting(false);
        setErr('Failed to save exemption image');
      });
  };

  const navToDateChange = () => {
    setReview(false);
    setErr('');
  };

  const navToUpload = (dir) => {
    if (!exempData.exempDate) {
      setErr(`Please select exemption date`);
      return;
    }
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploade`
    );
    setErr('');
  };

  const navToUploadToChange = (dir) => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploade/l`
    );
    setErr('');
  };

  const navToAccountInfo = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/regaccount/l`
    );
  };

  const onAcceptBoxClick = () => {
    if (!review) {
      setAcceptedTerms(!acceptedTerms);
    }
  };

  const navBack = () => {
    gs.dispatch({ type: 'set_exempImage', data: null });
    const nextUrl = testVacSelector.show ? 'selproof' : 'regaccount';
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/${nextUrl}`
    );
  };

  const onClose = () => {
    gs.dispatch({ type: 'set_exempImage', data: null });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  return (
    <SlidePage right={dirRight}>
      {submitting && <Overlay />}
      <PageContainer>
        <HeaderFlow
          label={
            review
              ? 'Confirm Medical/Religious Exemption Documentation'
              : 'Medical/Religious Exemption Documentation'
          }
          onLeave={review ? false : navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        {review ? (
          <ExempContainer>
            {err && (
              <div>
                <Error style={{ margin: '0 0 20px 0' }}>{err}</Error>
                {errAccount && (
                  <Link
                    onClick={navToAccountInfo}
                    style={{ margin: '-15px 0 30px 0' }}
                  >
                    Return to Account Information
                  </Link>
                )}
              </div>
            )}
            <Link style={{ marginBottom: '10px' }} onClick={navToDateChange}>
              Change Date
            </Link>
            <DateInput
              type='date'
              defaultValue={(exempData && exempData.exempDate) || ''}
              readOnly
            ></DateInput>
            <Link
              style={{ margin: '20px 0 5px 0' }}
              onClick={navToUploadToChange}
            >
              Upload another image
            </Link>
            <ImgPreview
              id='__vc_img_uplexemp_displ__'
              style={{ opacity: exempData && exempData.exempImage ? 1 : 0 }}
            />
          </ExempContainer>
        ) : (
          <ExempContainer>
            {exempConfig &&
              exempConfig.title &&
              exempConfig.title.split('#-#').map((l, idx) => (
                <Text3
                  key={idx}
                  style={{ marginBottom: '15px', whiteSpace: 'pre-line' }}
                >
                  {formatText(l)}
                </Text3>
              ))}
            {err && <Error style={{ margin: '10px 0 0 0' }}>{err}</Error>}
            <Text6 style={{ margin: '20px 0 10px 0' }}>
              What is the date of your medical/religious exemption
              documentation?
            </Text6>
            <DateInput
              type='date'
              value={exempData.exempDate || ''}
              onChange={(e) => onDateChange(e.target.value)}
              style={{ marginBottom: '35px' }}
            ></DateInput>
            {exempConfig &&
              exempConfig.info &&
              exempConfig.info.split('#-#').map((l, idx) => (
                <Text7
                  key={idx}
                  style={{ marginBottom: '15px', whiteSpace: 'pre-line' }}
                >
                  {formatText(l)}
                </Text7>
              ))}
            <ImgPreview
              id='__vc_img_uplexemp_displ__'
              style={{ opacity: exempData.exempImage ? 1 : 0 }}
            />
          </ExempContainer>
        )}
        {exempConfig.certifyText && (
          <CertifyContainer onClick={onAcceptBoxClick}>
            <Text7>{exempConfig.certifyText}</Text7>
            <Image
              style={{ marginTop: '3px', marginRight: '5px', height: '25px' }}
              src={
                acceptedTerms
                  ? `${imageCDN}checkbox_checked.png`
                  : `${imageCDN}uncheckbox.png`
              }
              alt='check box'
            />
          </CertifyContainer>
        )}
        {!exempConfig.certifyText || acceptedTerms ? (
          <Button
            onClick={review ? onClickSave : navToUpload}
            style={{ fontSize: '1.4rem' }}
          >
            {review
              ? 'Submit Exemption Documentation'
              : 'Continue to Upload Exemption Documentation'}
          </Button>
        ) : (
          <ButtonInactive style={{ fontSize: '1.4rem' }}>
            {review
              ? 'Submit Exemption Documentation'
              : 'Continue to Upload Exemption Documentation'}
          </ButtonInactive>
        )}
      </PageContainer>
    </SlidePage>
  );
};

export { AddExemp };
