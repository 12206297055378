import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import App from './App';
import { StateProvider } from './store.js';
import './css/index.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <StateProvider>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </StateProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// start local dev server from the app folder: yarn start
// start the local static app from the main parent folder: swa start http://localhost:3000 --api-location ./services
// navigate to localhost:4280
