import React from 'react';
import styled from 'styled-components';

const NoMatchMsg = styled.div`
  color: var(--color-primary);
  font-size: 1.8rem;
  margin: 10px;
`;

const NoRouteMatch = () => {
  return <NoMatchMsg>Invalid path</NoMatchMsg>;
};

export { NoRouteMatch };
