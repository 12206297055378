import React from 'react';
import styled from 'styled-components';
import { Spinner } from '.';

const Host = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 300;
`;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = () => {
  return (
    <Host>
      <Container>
        <Spinner />
      </Container>
    </Host>
  );
};

export { Overlay };
