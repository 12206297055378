import styled from 'styled-components';

export const Text1 = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 4.5rem;
  color: var(--color-dark-1);
`;

export const Text2 = styled.div`
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 3.2rem;
  color: var(--color-dark-1);
`;

export const Text3 = styled.div`
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 3rem;
  color: var(--color-dark-1);
`;

export const Text4 = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--color-dark-1);
`;

export const Text5 = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--color-dark-1);
`;

export const Text6 = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
  color: var(--color-dark-1);
`;

export const Text7 = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.3rem;
  color: var(--color-dark-1);

  & > p {
    margin-bottom: 20px;
  }
`;

export const Text8 = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7rem;
  color: var(--color-dark-1);
`;

export const Error = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: var(--color-err-1);
`;

export const Input = styled.input`
  height: 45px;
  width: 100%;
  margin: 3px 0 10px 0;
  padding: 5px 7px;
  box-sizing: border-box;
  border: 2px solid var(--color-dark-3);
  border-radius: 3px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--color-dark-1);
`;

export const Select = styled.select`
  height: 45px;
  width: 100%;
  margin: 3px 0 10px 0;
  padding: 5px 3px;
  box-sizing: border-box;
  border: 1px solid var(--color-dark-3);
  border-radius: 3px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--color-dark-1);
`;

export const Button = styled.div`
  position: relative;
  padding: 0 16px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(180deg, #3b47ef 0%, #0e19b9 100%);
  color: var(--color-light-1);
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
`;

export const ButtonInactive = styled.div`
  position: relative;
  padding: 16px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 8px;
  background-color: var(--color-dark-2);
  color: var(--color-light-1);
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  cursor: default;
`;

export const MenuButton = styled.div`
  display: grid;
  padding: 15px 16px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'icon1 text1 icon2' '. text2 icon2';
  grid-gap: 0 20px;
  border: 1px solid var(--color-light-2);
  border-radius: 10px;
  margin: 0 0 12px 0;
  user-select: none;
  cursor: pointer;
`;

export const MenuButtonIcon1 = styled.img`
  position: relative;
  top: 1px;
  grid-area: icon1;
  align-self: center;
`;

export const MenuButtonIcon2 = styled.img`
  grid-area: icon2;
  align-self: center;
`;

export const MenuButtonText1 = styled.div`
  grid-area: text1;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: var(--color-dark-1);
`;

export const MenuButtonText1A = styled(MenuButtonText1)`
  margin-top: 3px;
`;

export const Link = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: var(--color-link);
  cursor: pointer;
`;

export const DateInput = styled.input`
  height: 45px;
  min-height: 45px;
  width: 100%;
  margin: 3px 0 10px 0;
  padding: 5px 7px;
  border: 2px solid var(--color-dark-3);
  border-radius: 3px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--color-dark-1);
  box-sizing: border-box;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-light-2);
`;

export const Divider2 = styled.div`
  width: 100%;
  height: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--color-dark-2);
`;

export const Hihglight = styled.span`
  color: var(--color-link);
`;

export const Photo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 110px 0;
  border: 2px dashed #d3d3d3;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 15px 0;

  & > span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.9rem;
    color: var(--color-dark-1);
    cursor: pointer;
    user-select: none;
  }

  & > img {
    cursor: pointer;
  }

  & > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 200;
  }
`;

export const Spinner = () => {
  return (
    <i
      className='fad fa-circle-notch fa-spin'
      style={{
        height: '100px',
        width: '100px',
        color: 'var(--color-link)',
      }}
    ></i>
  );
};
