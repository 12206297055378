import axios from 'axios';
import { apiBaseUrl } from './config';

export const getUserInfo = async function () {
  const response = await fetch('/.auth/me');
  const payload = await response.json();
  const { clientPrincipal } = payload;
  return clientPrincipal;
};

export const getClientInfo = async function (clientCode, eventCode) {
  return axios.get(
    `${apiBaseUrl}GetClientInfo?clientCode=${clientCode}&eventCode=${eventCode}`,
    {}
  );
};

export const createUserRec = async function (data) {
  return axios.post(`${apiBaseUrl}CreateUserRec`, data, {});
};

export const storeImage = async function (data) {
  return axios.post(`${apiBaseUrl}StoreImage`, data, {});
};

export const getDashClientList = async function () {
  return axios.get(`${apiBaseUrl}GetDashClientList`, {});
};

export const getDashData = async function (client, event) {
  return axios.get(
    `${apiBaseUrl}GetDashData?client=${client}&event=${event}`,
    {}
  );
};

export const getImage = async function (client, event, imageId) {
  return axios.get(
    `${apiBaseUrl}GetImage?client=${client}&event=${event}&id=${imageId}`,
    {}
  );
};

export const updateUserRec = async function (data) {
  return axios.put(`${apiBaseUrl}UpdateUserRec`, data, {});
};

export const deleteUserRec = async function (data) {
  return axios.put(`${apiBaseUrl}DeleteUserRec`, data, {});
};

export const sendVerifEmail = async function (client, event, id) {
  return axios.post(`${apiBaseUrl}SendEmail`, {
    client,
    event,
    id,
  });
};
