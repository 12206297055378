import { v4 as uuidv4 } from 'uuid';

export const formatText = (str) => {
  let tags = [];
  let text = '';
  if (str) {
    const len = str.length;
    for (let i = 0; i < len; i++) {
      const subStr = str.substring(i);
      if (subStr.startsWith('#+u#')) {
        if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);
        text = '';
        const ePos = subStr.indexOf('#-u#') || subStr.length;
        tags.push(
          <u key={uuidv4()}>{formatText(subStr.substring(4, ePos))}</u>
        );
        i += 3 + ePos;
      } else if (subStr.startsWith('#+b#')) {
        if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);
        text = '';
        const ePos = subStr.indexOf('#-b#') || subStr.length;
        tags.push(
          <b key={uuidv4()}>{formatText(subStr.substring(4, ePos))}</b>
        );
        i += 3 + ePos;
      } else if (subStr.startsWith('#+r#')) {
        if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);
        text = '';
        const ePos = subStr.indexOf('#-r#') || subStr.length;
        tags.push(
          <span style={{color: 'maroon'}} key={uuidv4()}>{formatText(subStr.substring(4, ePos))}</span>
        );
        i += 3 + ePos;
      } else if (subStr.startsWith('#+a#')) {
        if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);
        text = '';
        const ePos = subStr.indexOf('#-a#') || subStr.length;
        const aTitleArr = subStr.substring(4, ePos).split('::');
        tags.push(
          <a key={uuidv4()} href={aTitleArr[0]}>
            {formatText(aTitleArr[1])}
          </a>
        );
        i += 3 + ePos;
      } else if (subStr.startsWith('#br#')) {
        if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);
        tags.push(<br key={uuidv4()}></br>);
        text = '';
        tags.push(formatText(subStr.substring(4)));
        i = len;
      } else {
        text += str[i];
      }
    }
  }

  if (text.length > 0) tags.push(<span key={uuidv4()}>{text}</span>);

  return tags;
};
