import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { store } from '../../store.js';
import { Divider2, SlidePage, Text3, Text4, Text7, Button } from '..';
import { imageCDN, clientImageCDN } from '../../config';
import { formatText } from '../../utils';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px 0;
`;

const LogoContainer = styled.div`
  display: grid;
  grid-template-rows: ${(props) => props.r1} ${(props) => props.r2};
  align-items: center;
  margin-bottom: ${(props) => props.bottomMargin};
  overflow-x: hidden;
`;

const SummaryContainer = styled.div`
  overflow: auto;
  padding: 0 10px;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
`;

const GoodToGo = (props) => {
  const gs = useContext(store);

  const { userData, landingInfo, clientInfo } = gs.state;

  const dirRight = props.match.params.dir === 'l' ? false : true;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;

  const navToLanding = () => {
    gs.dispatch({ type: 'reset_data' });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  const exitButton = landingInfo.exitButton ? landingInfo.exitButton : 'Exit';

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        {landingInfo && (
          <LogoContainer
            r1={landingInfo.logo.show ? landingInfo.logo.row : ''}
            r2={
              !landingInfo.gtglogo || landingInfo.gtglogo.show
                ? (landingInfo.gtglogo && landingInfo.gtglogo.row) ||
                  landingInfo.logo.row
                : ''
            }
            bottomMargin={landingInfo.gtgLogoAreaBottomMargin}
          >
            {landingInfo && landingInfo.logo.show && (
              <Image>
                <img
                  width={landingInfo.logo.w}
                  height={landingInfo.logo.h}
                  src={`${clientImageCDN}${landingInfo.logo.name}`}
                  alt='logo'
                />
              </Image>
            )}
            {(!landingInfo.gtglogo || landingInfo.gtglogo.show) && (
              <Image>
                <img
                  width={
                    landingInfo.gtglogo
                      ? landingInfo.gtglogo.w
                      : landingInfo.vclogo.w
                  }
                  height={
                    landingInfo.gtglogo
                      ? landingInfo.gtglogo.h
                      : landingInfo.vclogo.h
                  }
                  src={`${imageCDN}${
                    (landingInfo.gtglogo && landingInfo.gtglogo.name) ||
                    'go.png'
                  }`}
                  alt='green check'
                />
              </Image>
            )}
          </LogoContainer>
        )}
        <SummaryContainer>
          <Text3 style={{ color: 'var(--color-link)', marginBottom: '10px' }}>
            Thank you for your submission!
          </Text3>
          {landingInfo &&
            landingInfo.endOfSubmissionMessage &&
            landingInfo.endOfSubmissionMessage
              .replace('#event#', clientInfo.title || '')
              .replace('#dates#', clientInfo.textDates || '')
              .split('#-#')
              .map((l, idx) => (
                <Text4
                  key={idx}
                  style={{
                    marginBottom: '15px',
                    whiteSpace: 'pre-line',
                    lineHeight: '2.4rem',
                  }}
                >
                  {formatText(l)}
                </Text4>
              ))}
          <Text4 style={{ color: 'var(--color-link)', marginTop: '55px' }}>{`${
            userData.firstName || ''
          } ${userData.lastName || ''}`}</Text4>
          <Divider2 />
          <Text7>First {'&'} Last Name</Text7>
          <Text4 style={{ color: 'var(--color-link)', marginTop: '25px' }}>
            {moment().format('MM/DD/yyyy')}
          </Text4>
          <Divider2 />
          <Text7>Date of Submission</Text7>
          {!landingInfo.supressPositiveResultWarning && (
            <Text3 style={{ marginTop: '30px' }}>
              {landingInfo.positiveResultWarning
                ? landingInfo.positiveResultWarning
                : 'A positive result will forfeit your attendance, please consult your local healthcare provider for further guidance.'}
            </Text3>
          )}
        </SummaryContainer>
        <Button onClick={navToLanding}>{exitButton}</Button>
      </PageContainer>
    </SlidePage>
  );
};

export { GoodToGo };
