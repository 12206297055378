import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { imageCDN } from '../../config';

const PageContainer = styled.div`
  position: fixed;
  height: 96%;
  width: 96%;
  top: 2%;
  left: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
`;

const Header = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  z-index: 201;
  background-color: var(--color-light-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ImgPreview = styled.img`
  position: relative;
  overflow: auto;
`;

const PdfPreview = styled.object`
  position: relative;
  overflow: auto;
`;

export const ImagePreview = (props) => {
  const { image, type, onClose } = props;

  const [imgStyle, setImageStyle] = useState({});

  const id = uuidv4();

  useEffect(() => {
    const imgCont = document.getElementById(id);
    if (image && imgCont) {
      if (type === 'application/pdf') {
        imgCont.data = image;
        setImageStyle({ width: '100%', height: '100%' });
      } else {
        imgCont.src = image;
        if (imgCont.height > imgCont.width) {
          setImageStyle({ maxHeight: '100%', width: 'auto' });
        } else {
          setImageStyle({ maxWidth: '100%', height: 'auto' });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer onClick={onClose}>
      <Header>
        <img src={`${imageCDN}times.png`} alt='close' onClick={onClose} />
      </Header>
      {image && type === 'application/pdf' && (
        <PdfPreview id={id} style={imgStyle} />
      )}
      {image && type !== 'application/pdf' && (
        <ImgPreview id={id} style={imgStyle} />
      )}
    </PageContainer>
  );
};

export default ImagePreview;
