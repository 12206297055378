import React from 'react';
import styled from 'styled-components';
const Header = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0 20px;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 56px;
  margin: 0;

  & > span {
    font-weight: 600;
    font-size: 1.8rem;
    color: var(--color-dark-1);
    user-select: none;
    line-height: 2rem;
  }
`;

const Icon = styled.span`
  cursor: pointer;
`;

const HeaderFlow = (props) => {
  const navigateOnClose = () => {
    if (props.closeHandler) {
      props.closeHandler();
    }
  };

  return (
    <Header>
      {props.onLeave ? (
        <Icon onClick={props.onLeave}>
          <i
            className='far fa-arrow-to-left'
            style={{ height: '30px', width: '30px' }}
          ></i>
        </Icon>
      ) : (
        <div></div>
      )}
      <span>{props.nolabel ? '' : props.label || ''}</span>
      {props.showClose ? (
        <Icon onClick={navigateOnClose}>
          <i
            className='fal fa-times'
            style={{ height: '30px', width: '30px' }}
          ></i>
        </Icon>
      ) : (
        <span></span>
      )}
    </Header>
  );
};

export { HeaderFlow };
