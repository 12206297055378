import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import {
  SlidePage,
  HeaderFlow,
  Divider,
  Text5,
  Text6,
  Text7,
  Hihglight,
  Button,
  ButtonInactive,
} from '..';
import { formatText } from '../../utils';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 15px 0;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

const ContentSubContainer = styled.div`
  positiion: absolute;
  max-width: 350px;
  overflow: hidden;
  box-sizing: border-box;
`;

const P = styled.div`
  margin-bottom: 20px;
`;

const SubP = styled.div`
  margin-top: 20px;
`;

const AcceptContainer = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-gap: 0 20px;
  align-items: center;
  cursor: pointer;
`;

const SectionHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
`;

const PrivacyPolicy = (props) => {
  const gs = useContext(store);
  const { acceptedTerms, clientInfo, landingInfo } = gs.state;

  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;
  const dirRight = props.match.params.dir === 'l' ? false : true;

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isCustomTermsOpen, setIsCustomTermsOpen] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventDates, setEventDates] = useState('');

  useEffect(() => {
    setEventName(
      clientInfo && clientInfo.title ? ` of ${clientInfo.title}` : ''
    );
    setEventDates(
      clientInfo && clientInfo.textDates ? ` on  ${clientInfo.textDates}` : ''
    );
  }, [clientInfo]);

  const onPrivacyPolicyOpenClick = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  };

  const onTermsOpenClick = () => {
    setIsTermsOpen(!isTermsOpen);
  };

  const onCustomTermsOpenClick = () => {
    setIsCustomTermsOpen(!isCustomTermsOpen);
  };

  const navBack = () => {
    props.history.push(`/${clientPath}${eventPath ? '/' + eventPath : ''}`);
  };

  const onAcceptBoxClick = () => {
    gs.dispatch({ type: 'accept_terms', data: !acceptedTerms });
  };

  const onContinueClick = () => {
    if (acceptedTerms) {
      props.history.push(
        `/${clientPath}${eventPath ? '/' + eventPath : ''}/regaccount`
      );
    }
  };

  return (
    <SlidePage right={dirRight}>
      <PageContainer>
        <HeaderFlow
          label='Review and accept'
          onLeave={navBack}
          showClose
          closeHandler={navBack}
        ></HeaderFlow>
        <ContentContainer>
          <ContentSubContainer>
            <Text5>{`As an attendee${eventName}${eventDates}, I agree and accept the following Privacy Policy, Terms of Use, and Event Attendance Requirements.`}</Text5>
            <AcceptContainer
              onClick={onAcceptBoxClick}
              style={{ margin: '40px 0 60px 0' }}
            >
              {acceptedTerms && (
                <span>
                  <i
                    className='fas fa-check-square'
                    style={{
                      height: '35px',
                      width: '35px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
              {!acceptedTerms && (
                <span>
                  <i
                    className='far fa-square'
                    style={{
                      height: '35px',
                      width: '35px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
              <Text7>
                By checking this box, I agree to the following Terms of Use,
                Privacy Policy, and Event Attendance Requirements
              </Text7>
            </AcceptContainer>
            <Divider></Divider>
            <SectionHeaderContainer onClick={onPrivacyPolicyOpenClick}>
              <Text6>Privacy Policy</Text6>
              {isPrivacyPolicyOpen && (
                <span>
                  <i
                    className='far fa-chevron-down'
                    style={{
                      height: '20px',
                      width: '20px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
              {!isPrivacyPolicyOpen && (
                <span>
                  <i
                    className='far fa-chevron-right'
                    style={{
                      height: '20px',
                      width: '20px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
            </SectionHeaderContainer>
            {isPrivacyPolicyOpen && (
              <Text7>
                <P>
                  <SubP>Last updated: November 15, 2021</SubP>
                </P>
                <p>
                  Attend Safe, LLC (“Attend Safe”," us", "we", or "our")
                  operates the Attend Safe mobile application (hereinafter
                  referred to as the "Service"). Attend Safe’s commitment to
                  privacy means we will only use information that identifies,
                  relates to, describes, references, is capable of being
                  associated with, or could reasonably be linked, directly or
                  indirectly, with a particular individual (“Personal
                  Information”) in accordance with the terms of this Privacy
                  Policy (“Policy”).
                </p>
                <p>
                  Attend Safe provides several services to customers (“Clients”)
                  including contact tracing, medical testing, vaccination
                  verification and assorted other related services to monitor
                  the health and safety of event attendees, worksite employees
                  and other such situations. Those who provide the information,
                  the end users of the Services (“Users”) are the subject of
                  this Policy.
                </p>
                <p>
                  The Policy describes how we collect, use, protect and disclose
                  Personal Information we obtain through the websites we own or
                  control (today or in the future), including attendsafe.com,
                  attendsafe.health, attendsafe.com and all sites created for
                  individual event or clients use(collectively, the “Sites”),
                  your use of any mobile applications we own or control (today
                  or in the future), including those created for individual
                  event or client use (collectively, the “Applications” or
                  “Apps”), and when you use any services we provide through the
                  Sites or the Apps (collectively, together with the Sites and
                  the Apps, the “Services”). You can access the Sites in many
                  ways, including from a computer, tablet, mobile phone or other
                  internet enabled device, and this Policy will apply regardless
                  of the means of access. This Policy also governs the use of
                  Personal Information we obtain from you and any third-party
                  site or application where we post content or invite your
                  feedback or participation. Attend Safe cannot control the
                  privacy policies or practices of sites or of companies that we
                  do not own or control and cannot control the actions of people
                  we do not employ or manage.
                </p>
                <p>
                  Please read this Policy carefully. This Policy is a legally
                  binding contract between us and anyone who accesses or uses
                  any of the Services. This Policy is incorporated into our
                  Terms of Use Policy (“TUP”) for the Sites and Applications. In
                  the event of a conflict between this Policy and our TUP, the
                  TUP will control. If you have any questions about this Policy,
                  please email us at privacy@attendsafe.com. If you do not want
                  us to collect, use or share your Personal Information in the
                  ways described in this Policy, please do not use the Services.
                  By using the Services, you consent to the collection and use
                  of your information (including Personal Information) by us as
                  identified in this Policy.
                </p>
                <p>
                  <b>Personal Information Collected</b>
                </p>
                <p>
                  This section describes what Personal Information we collect
                  and the different ways we collect it. Please note that
                  Personal Information does not include information that is
                  publicly available information from government records or
                  de-identified or aggregated consumer information.
                </p>
                <p>
                  <b>Information We Collect from Users</b>
                </p>
                <p>
                  The use of certain Services requires that you to provide
                  certain Personal Information and, in some cases, requires you
                  to register for an account. While using the Services we
                  collect certain Personal Information including, but not
                  limited to, your name, your email address, gender, photo, and
                  date of birth, as well as health and symptom information and
                  vaccination status. In addition, if contact-tracing is part of
                  the client agreement, we will collect the device-to-device
                  interactions through Bluetooth but will not record GPS
                  coordinates. Where applicable, we indicate whether you must
                  provide us with your Personal Information in order to use the
                  Services. If you do not provide the Personal Information, you
                  may not be able to benefit from the Services if that
                  information is necessary to provide you with the applicable
                  Services or if we are legally required to collect it.
                </p>
                <p>
                  When you register for an account, we may access and collect
                  the Personal Information you provide, such as your name,
                  contact information (including your mailing and billing
                  address), e-mail address, date of birth, phone number,
                  username, and password. We may also ask for additional
                  Personal Information including your mobile phone number and,
                  depending on the terms of the contract with our Client, your
                  credit or debit card number or other financial account number.
                  We will also collect and maintain information about all
                  transactions associated with your account.
                </p>
                <p>
                  When you use some of our other Services, you may choose to
                  provide certain information directly to us to request more
                  information about our Services or for other reasons. We will
                  collect, use, share and store this Personal Information
                  consistent with this Policy.
                </p>
                <p>
                  <b>Personal Information Collected from Third Parties</b>
                </p>
                <p>
                  We may collect your Personal Information from third-party
                  business partners such as social media sites, ad networks and
                  analytics providers. We may also collect your Personal
                  Information from others that refer you to our Services
                  including our Clients who facilitate our ability to provide
                  Services.
                </p>
                <p>
                  <b>Personal Information Automatically Collected</b>
                </p>
                <p>
                  When you use our Sites and Applications, our servers
                  automatically record information that your browser or device
                  sends whenever you visit a website or utilize an application.
                  This information may include your IP address, the type of
                  device you are using, the internet service provider or mobile
                  carrier you are using, your device identifiers, your mobile
                  telephone number, your geographic location and your activities
                  within the Services, including the links you click, the pages
                  or screens you view, your session time, the number of times
                  you click a page/screen or use a feature of the Services, the
                  date and time you click on a page or use a feature and the
                  amount of time you spend on a page or using a feature.
                </p>
                <p>
                  Like many websites, our Sites also use “cookie” technology to
                  collect additional website usage data and improve the Sites
                  and our Services. A cookie is a small data file that we
                  transfer to your computer’s hard disk. A session cookie
                  enables certain features of the Sites and is deleted from your
                  computer when you disconnect from or leave the Sites. A
                  persistent cookie remains after you close your browser and may
                  be used by your browser on subsequent visits to our Sites. We
                  may use both session and persistent cookies to better
                  understand how you interact with our Sites, to monitor
                  aggregate usage by our uses and web traffic routing on out
                  Sites, and to improve our Sites and Services. You may also be
                  able to configure your computer or mobile device to limit the
                  collection of these cookies, but that limitation may also
                  limit our ability to provide all the services or
                  functionalities of the Sites. For more information about
                  cookies, including how to set your internet browser to reject
                  cookies, please go to{' '}
                  <Hihglight>www.allaboutcookies.org</Hihglight>.
                </p>
                <p>
                  We may also automatically record certain information from your
                  device by using various types of technology, including “clear
                  gifs” or “web beacons.” This automatically collected
                  information may include your IP address or other device
                  address or ID, web browser and/or device type, the web pages,
                  or sites that you visit just before or after you visit our
                  Sites, the pages or other content you view or otherwise
                  interact with on our Sites and the dates and times that you
                  visit, access, or use our Sites. We may also use these
                  technologies to collection information regarding your
                  interaction with email messages, such as whether you opened,
                  clicked on, or forwarded a message, to the extent permitted
                  under applicable law.
                </p>
                <p>
                  Our Sites and Applications use Google Analytics (and in the
                  future may use other similar services), a web analytics
                  service provided by Google, Inc. (“Google”), to better assist
                  us in understanding how the Sites are used. Google Analytics
                  will place cookies on your computer that will generate
                  information that we select about your use of the Sites and the
                  Applications, including your computer’s IP address. That
                  information will be transmitted to and stored by Google. The
                  information will be used for the purpose of evaluating
                  customer use of the Sites and Applications, compiling reports
                  on Site and Application activity for our use, and providing
                  other services relating to Site and Application activity and
                  usage. Google may also transfer this information to third
                  parties where required to do so by law, or where such third
                  parties process the information on Google’s behalf. You may
                  refuse the use of these cookies by selecting the appropriate
                  settings on your browser or mobile device. Please note that by
                  doing so, you may not be able to use the full functionality of
                  the Sites and Applications. The use of cookies by Google
                  Analytics is covered by Google’s privacy policy. From time to
                  time, we may enter into agreements with other third-party
                  providers for web analytic and other similar services. If we
                  enter into such an agreement, we may share your information,
                  including your Personal Information, with the provider or such
                  provider may have access to and collect your information on
                  our behalf. Your information, including Personal Information,
                  may be stored with the provider.
                </p>
                <p>
                  Please note that we have not yet developed a response to
                  browser “Do Not Track” signals, and do not change any of our
                  data collection practices when we receive such signals. We
                  will continue to evaluate potential responses to “Do Not
                  Track” signals considering industry developments or legal
                  changes.
                </p>
                <p>
                  <b>Information Collected by Third Parties</b>
                </p>
                <p>
                  Depending on our agreement with the Client, we may display
                  third-party content on the Sites and Applications, including
                  third-party advertising. Third-party content may use cookies,
                  web beacons or other mechanisms for obtaining data in
                  connection with your viewing of the third-party content on our
                  Sites and Applications. Additionally, we may implement
                  third-party buttons, such as Facebook “share” buttons, that
                  may function as web beacons even when you do not interact with
                  the button. Information collected through third-party web
                  beacons and buttons is collected directly by these third
                  parties, not by Attend Safe. Please consult such third party’s
                  data collection, use and disclosure policies for more
                  information.
                </p>
                <p>
                  Our Sites and Applications may contain links to other
                  websites. The fact that we link to a website is not an
                  endorsement, authorization, or representation of our
                  affiliation with that third party. We do not exercise control
                  over third-party websites. These other websites may place
                  their own cookies or other files on your computer or mobile
                  device, collect data or solicit Personal Information from you.
                  Other sites follow different rules regarding the use or
                  disclosure of the Personal Information you submit to them. We
                  are not responsible for the content, privacy and security
                  practices, and policies of third-party sites or services to
                  which links or access are provided through our Sites. We
                  encourage you to read the privacy policies or statements of
                  the other websites you visit.
                </p>
                <p>
                  <b>How We Use the Information We Collect</b>
                </p>
                <p>
                  We may use your Personal Information for various purposes,
                  including, but not limited to, the following:
                </p>
                <p>
                  <i>Non-Marketing Purposes</i>
                </p>
                <p>
                  We may use the Personal Information and other information we
                  collect for non-marketing purposes including: (1) validating
                  your identity; (2) sending you push notifications, text
                  messages, or emails to provide you with alerts and updates
                  about your account and the Services; (3) processing your
                  transactions; (4) conducting statistical or demographic
                  analysis; (5) complying with legal and regulatory
                  requirements; (6) customizing your experience with the
                  Services; (7) protecting and defending Attend Safe and its
                  affiliates against legal actions or claims; (8) preventing
                  fraud; (9) debt collection; (10) satisfying contractual
                  obligations; (11) cooperating with law enforcement or other
                  government agencies for purposes of investigations, national
                  security, public safety or matters of public importance when
                  we believe that disclosure of Personal Information is
                  necessary or appropriate to protect the public interest; and
                  (12) for other business purposes permitted under applicable
                  law (collectively, “Non-Marketing Purposes”).
                </p>
                <p>
                  <i>Other Purposes</i>
                </p>
                <p>
                  Attend Safe also uses the collected data for the following
                  public health objectives:
                </p>
                <p>
                  1. Helping your employer or administrator understand when
                  symptoms are developing at work, at your school, or in your
                  community group;
                </p>
                <p>
                  2. Gaining a better understanding the symptoms of COVID-19;
                </p>
                <p>
                  3. Tracking the spread of COVID-19 across geographies and
                  within employers, schools, and households;
                </p>
                <p>
                  4. Maintaining User status related to Covid-19 vaccination
                  status and/or test results to verify participation eligibility
                  at events hosted by our clients.
                </p>
                <p>
                  We may also use information in the aggregate to understand how
                  our users as a group use the Services provided on our Sites
                  and Applications. Attend Safe will not collect additional
                  categories of Personal Information or use the Personal
                  Information we collect for materially different, unrelated, or
                  incompatible purposes without providing you notice.
                </p>
                <p>
                  <b>Sharing of Personal Information</b>
                </p>
                <p>
                  For business purposes, we may share certain Personal
                  Information we receive from and about you, and about your
                  transactions with us, with our affiliates and certain third
                  parties. Doing so allows us to provide the Services you
                  request. We may also share your Personal Information with the
                  following third parties:
                </p>
                <p>
                  <i>Third-Party Service Providers</i>
                </p>
                <p>
                  Your Personal Information may be shared with or collected by
                  third-party service providers who provide us with services,
                  including, but not limited to data hosting or processing and
                  credit card processing. We require these third-party service
                  providers to exercise reasonable care to protect your Personal
                  Information and restrict their use of your Personal
                  Information to the purposes for which it was provided to them.
                </p>
                <p>
                  <i>Anonymous Information</i>
                </p>
                <p>
                  We may provide anonymized information to third parties,
                  including governmental agencies for purposes of contact
                  tracing and/or any governmental agency that requires COVID-19
                  outbreak/cluster data to be reported. Any anonymized
                  information we provide to third parties is not considered
                  Personal Information and is not subject to the terms of this
                  Policy.
                </p>
                <p>
                  <i>Sale of our Business; Bankruptcy</i>
                </p>
                <p>
                  In the event of a merger, acquisition, bankruptcy or other
                  sale of all or a portion of our assets, we may transfer or
                  allow third parties to use information owned or controlled by
                  us, which may include your Personal Information. We reserve
                  the right, in connection with these types of transactions, to
                  transfer or assign your Personal Information and other
                  information we have collected from our customers to third
                  parties or to authorize third parties to use any such
                  information retained by us. Other than to the extent ordered
                  by a bankruptcy or other court, the use and disclosure of all
                  transferred Personal Information will be subject to this
                  Policy. However, any information you submit or that is
                  collected after this type of transfer may be subject to a new
                  privacy policy adopted by the successor entity.
                </p>
                <p>
                  <i>Compliance with Laws and Law Enforcement</i>
                </p>
                <p>
                  Attend Safe cooperates with government and law enforcement
                  officials or private parties to enforce and comply with the
                  law. To the extent permitted under applicable law, we may
                  disclose any information about you, including, but not limited
                  to, your Personal Information, to government or law
                  enforcement officials or private parties as we believe is
                  necessary or appropriate to investigate, respond to, and
                  defend against legal claims, for legal process (including
                  subpoenas), to protect the property and rights of Attend Safe
                  or a third party, to protect Attend Safe against liability,
                  for the safety of the public or any person, to prevent or stop
                  any illegal, unethical, fraudulent, abusive, or legally
                  actionable activity, to protect the security or integrity of
                  the Services and any equipment used to make the Services
                  available, or to comply with applicable law.
                </p>
                <p>
                  <b>Collection/Processing of Payment Card Information</b>
                </p>
                <p>
                  If you use our Services in any transaction involving payment,
                  you may be asked to provide payment information, including,
                  but not limited to, payment card information. We will not ask
                  you, and you are not permitted, to provide any payment card
                  information via email, text, or other electronic means
                  (excluding through the dedicated forms on the Sites and Apps).
                  Any payment card information you properly provide to us is
                  used exclusively to process payments for Services and related
                  products you receive from us. Under no circumstances do we
                  collect or keep payment card information on our servers.
                </p>
                <p>
                  <b>Managing Your Personal Information</b>
                </p>
                <p>
                  You may decline to share certain information with us, in which
                  case, we may not be able to provide you some of the Services
                  we offer. To protect your privacy and security, we take
                  reasonable steps to verify your identity before granting your
                  account access or making corrections to your information. You
                  are responsible for always maintaining the secrecy of your
                  unique password and account information.
                </p>
                <p>
                  <i>Text Message Opt-Out</i>
                </p>
                <p>
                  If you have provided us with your prior express consent to
                  receive autodialed text messages, we may use your information
                  to send text messages to any mobile device connected to the
                  cell phone number you provided until you opt out from future
                  text messages. You can opt out from receiving text messages as
                  described in the TUP, including by replying STOP or similar
                  described text to any text message from us. You agree to and
                  will then receive a single final text message confirming your
                  opt-out of the text-messaging program.
                </p>
                <p>
                  <i>Email Opt-Out</i>
                </p>
                <p>
                  From time to time (and with your consent when required), you
                  may receive periodic mailings or emails from us with news or
                  other information on events, products, services, discounts,
                  special promotions, upcoming events, or other offers from us
                  or on our behalf. If at any time you wish to stop receiving
                  emails or mailings from us, please send us an email to
                  privacy@attendsafe.com with the phrase “Privacy Opt-Out:
                  Attend Safe Mailings” in the subject line, or write to us at
                  the address provided below, and we will remove you from our
                  mailing list.
                </p>
                <p>
                  Alternatively, for email communications, you may opt out of
                  receiving such communications by following the unsubscribe
                  instructions set forth in most promotional email messages from
                  us. Your unsubscribe request or email preferences change will
                  be processed promptly, though this process is usually not
                  immediate. During that processing period, you may receive
                  additional promotional emails from us, as noted in the
                  acknowledgement to your unsubscribe actions.
                </p>
                <p>
                  Please note that opting out of emails and other communications
                  will only apply to marketing and other promotional emails and
                  will not apply to any email and other communications sent for
                  Non-Marketing Purposes, including, but not limited to, emails
                  and other communications about your account or your use of the
                  Services.
                </p>
                <p>
                  <i>Marketing/Joint Marketing Opt-Out</i>
                </p>
                <p>
                  If you wish to opt out of receiving solicitations from us, or
                  do not want us to share your Personal Information with Attend
                  Safe’s affiliates or partners for marketing purposes, you may
                  email us at privacy@attendsafe.com or write to: PO Box 440,
                  St. Charles Illinois 60174.
                </p>
                <p>
                  <b>Security</b>
                </p>
                <p>
                  Attend Safe is committed to protecting the security of the
                  Personal Information you provide to us and that we collect
                  about you. We maintain commercially reasonable safeguards to
                  maintain the security and privacy of Personal Information we
                  collect. Nevertheless, when disclosing Personal Information,
                  you should remain mindful that there is an inherent risk in
                  the use of email and the internet. Your information may be
                  intercepted without your consent, collected illegally, and
                  used by others. We cannot guarantee the security of any
                  information you disclose online, and you do so at your own
                  risk.
                </p>
                <p>
                  <b>Underage Persons</b>
                </p>
                <p>
                  The Services are for use by persons who are at least 18 years
                  of age. If you are younger than 18, you may not access,
                  attempt to access, or use the Services. We do not knowingly
                  collect or allow the collection of Personal Information via
                  the Services from persons underage, pursuant to applicable law
                  and regulations, without parental consent. We do not knowingly
                  collect or allow the collection of Personal Information via
                  the Services from persons under the age of 13 without written
                  parental consent. If we learn that we have collected the
                  Personal Information of someone under the age of 13 without
                  parental consent, we will take appropriate steps to delete
                  this information. If you are a parent or guardian of someone
                  under the age of 13 and discover that your child has submitted
                  Personal Information, you may contact us at
                  privacy@attendsafe.com and ask us to remove your child’s
                  Personal Information from our systems.
                </p>
                <p>
                  <b>Notice to Individuals Outside the United States</b>
                </p>
                <p>
                  Our Services are only intended for individuals located in the
                  United States. If you are not located in the United States,
                  please do not provide us with any Personal Information or
                  attempt to use our Services.
                </p>
                <p>
                  <b>Changes to this Policy</b>
                </p>
                <p>
                  We may revise this Policy from time to time in our sole
                  discretion. We will notify you of any material revisions by
                  placing notice of the revised Policy on our website or any
                  place through which you access the Services. Your continued
                  access or use of the Services following any amendments to the
                  Policy constitutes your acceptance of the Policy as amended.
                  It is your sole responsibility to monitor our website for
                  changes to the Policy.
                </p>
                <p>
                  <b>Contact Us</b>
                </p>
                <p>
                  If you would like to update or correct your information,
                  modify your communication preferences, or if you have
                  questions regarding our Privacy Policy or the use of
                  information collected, please contact us using the following
                  information:
                </p>
                <p>PO Box 440, St. Charles, IL 60174</p>
                <p>
                  <Hihglight>privacy@attendsafe.com</Hihglight>
                </p>
              </Text7>
            )}
            <Divider />
            <SectionHeaderContainer onClick={onTermsOpenClick}>
              <Text6>Terms of use</Text6>
              {isTermsOpen && (
                <span>
                  <i
                    className='far fa-chevron-down'
                    style={{
                      height: '20px',
                      width: '20px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
              {!isTermsOpen && (
                <span>
                  <i
                    className='far fa-chevron-right'
                    style={{
                      height: '20px',
                      width: '20px',
                      color: 'var(--color-link)',
                    }}
                  ></i>
                </span>
              )}
            </SectionHeaderContainer>
            {isTermsOpen && (
              <Text7>
                <P>
                  <SubP>Last updated: November 15, 2021</SubP>
                </P>
                <p>
                  Attend Safe LLC. (“we”, “us”, “our”, “Attend Safe”) provides
                  web-based symptom and contact tracing, vaccination
                  verification and physical testing with reporting, all through
                  licensed technologies, to organizations. Our technology does
                  not prevent the spread of any disease and is designed to give
                  individuals and organizations information they need to make
                  decisions based on Violation of this Terms of Use Policy
                  (“TUP”) may lead to suspension or termination of the user’s
                  account or legal action. In addition, the user may be required
                  to pay for the costs of investigation and remedial action
                  related to TUP violations. Attend Safe reserves the right to
                  take any other remedial action it sees fit.
                </p>
                <p>
                  Attend Safe requires that all customers and other users of
                  Vendor’s cloud-based services (the “Service”) conduct
                  themselves with respect for others. Observe the following
                  rules in your use of the Service: i. Do not harass, threaten,
                  or defame any person or entity, ii. Do not contact any person
                  who has requested no further contact, iii. Do not use ethnic
                  or religious slurs against any person or group, iv. Do not
                  violate the privacy rights of any person, v. Do not collect or
                  disclose any personal address, social security number, or
                  other personally identifiable information without each
                  holder’s written permission, vi. Do not cooperate in or
                  facilitate identity theft, vii. Do not infringe upon the
                  copyrights, trademarks, trade secrets, or other intellectual
                  property rights of any person or entity, viii. Do not
                  reproduce, publish, or disseminate software, audio recordings,
                  video recordings, photographs, articles, or other works of
                  authorship without the written permission of the copyright
                  holder, ix. Do not access any computer or communications
                  system without authorization, including the computers used to
                  provide the Service, x. Do not attempt to penetrate or disable
                  any security system, xi. Do not intentionally distribute a
                  computer virus, launch a denial of service attack, or in any
                  other way attempt to interfere with the functioning of any
                  computer, communications system, or website, xii. Do not
                  attempt to access or otherwise interfere with the accounts of
                  other users of the Service, xiii. Do not send bulk unsolicited
                  e-mails (“Spam”) or sell or market any product or service
                  advertised by or connected with Spam, xiv. Do not facilitate
                  or cooperate in the dissemination of Spam in any way, xv. Do
                  not violate the CAN-Spam Act of 2003, xvi. Do not issue
                  fraudulent offers to sell or buy products, services, or
                  investments, xvii. Do not mislead anyone about the details or
                  nature of a commercial transaction, xviii. Do not commit fraud
                  in any other way, xix. Do not violate any law.
                </p>
                <p>
                  As it relates to our texting programs, this TUP should be read
                  carefully.{' '}
                  <i>
                    YOU EXPRESSLY CONSENT TO RECEIVE AUTOMATED TEXT MESSAGES
                    FROM ATTEND SAFE.
                  </i>
                </p>
                <p>
                  By enabling text reminders from Attend Safe, you expressly
                  consented to receive text messages made using an automatic
                  telephone dialing system (an “autodialer”) to any mobile phone
                  number you provided to us in creating your account. You also
                  warrant and represent that you are either the account owner of
                  any mobile numbers you provide or have the express permission
                  of the account holder to provide such numbers.
                </p>
                <p>
                  You must be 18 or older to enroll in attend Safe’s texting
                  program. You, not Attend Safe, will be solely responsible for
                  any carrier charges because of text messages from us. The
                  following carriers are currently supported by our texting
                  program: ACS Alaska, AT{'&'}T, Alltel, Appalachian Wireless,
                  Bluegrass Cellular, Boost (Sprint), Cellcom, Cellular One, C
                  Spire (formerly Cellular South), Cincinnati Bell, Cricket,
                  Cross, Eastern Kentucky Network, Element Mobile, Epic Touch,
                  GCI Communications, Golden State, Hawkeye (Chat Mobility),
                  Hawkeye (NW Missouri Cellular), Illinois Valley Cellular,
                  Immix Wireless, Inland Cellular, iWireless, MetroPCS, Mobi
                  PCS, Mosaic, Nex-Tech Communications, nTelos, Panhandle,
                  Pioneer Cellular, Plateau Wireless, Revol Wireless, Simmetry
                  Wireless, Sprint/Nextel, Syringa Wireless, T-Mobile, Thumb
                  Cellular, U.S. Cellular, Union Wireless, United Wireless,
                  Verizon Wireless, Virgin Mobile, West Central Wireless.
                  Additional carriers may be added as they become supported.
                </p>
                <p>
                  You may text HELP to any text you receive from us at any time
                  if you require assistance with the texting program.
                  Alternatively, you may email us at privacy@attendsafe.app.
                  When emailing or support, please be sure to include your
                  mobile number and a detailed description of your question or
                  support issue. To opt-out of texts, text STOP to any text you
                  receive from us at any time. You agree to and will then
                  receive a single final text message confirming your opt-out of
                  the texting program. You agree not to send or otherwise make
                  available content that is unlawful, harmful, threatening,
                  abusive, harassing, tortious, defamatory, vulgar, obscene,
                  libelous, invasive of another's privacy, or contains hate
                  speech. You acknowledge that we may or may not monitor or
                  screen mobile message content you provide but shall have the
                  right, without obligation and in its sole discretion, to
                  monitor, screen, or refuse any content you make available,
                  including content that violates these terms and conditions.
                </p>
                <p>
                  Attend Safe is not responsible for incomplete, lost, late, or
                  misdirected messages, including, but not limited to,
                  undelivered messages resulting from any form of filtering by
                  your mobile carrier or service provider, and we do not
                  guarantee that each user will receive the benefit of the
                  texting program for each text message sent. You agree that if
                  and before you disconnect or transfer your mobile number, you
                  will send all necessary texts or other communications to us in
                  order to stop future text messages from being sent to that
                  number. We reserve the right, in our sole discretion, to
                  cancel or suspend any or all of its texting program, in whole
                  or in part, for any reason, with or without notice to you.
                </p>
                <p>
                  To report unacceptable use under the TUP we request that
                  anyone with information about a violation report it via e-mail
                  to <Hihglight>privacy@attendsafe.app</Hihglight> or via mail
                  to PO Box 440, St. Charles, IL 60174. Please provide the date
                  and time (with time zone) of the violation and any identifying
                  information regarding the violator, including e-mail or IP
                  (Internet Protocol) address if available, as well as details
                  of the violation.
                </p>
                <p>
                  Attend Safe reserves the right to change this TUP at any time
                  by posting a new version on this page or sending the user
                  written notice thereof. The new version will become effective
                  on the stated date of such policy.
                </p>
              </Text7>
            )}
            {landingInfo.termsofuse && landingInfo.termsofuse.title && landingInfo.termsofuse.content && (
              <Fragment>
                <Divider />
                <SectionHeaderContainer onClick={onCustomTermsOpenClick}>
                  <Text6>{landingInfo.termsofuse.title}</Text6>
                  {isCustomTermsOpen && (
                    <span>
                      <i
                        className='far fa-chevron-down'
                        style={{
                          height: '20px',
                          width: '20px',
                          color: 'var(--color-link)',
                        }}
                      ></i>
                    </span>
                  )}
                  {!isCustomTermsOpen && (
                    <span>
                      <i
                        className='far fa-chevron-right'
                        style={{
                          height: '20px',
                          width: '20px',
                          color: 'var(--color-link)',
                        }}
                      ></i>
                    </span>
                  )}
                </SectionHeaderContainer>
              </Fragment>
            )}
            {landingInfo.termsofuse && landingInfo.termsofuse.title && landingInfo.termsofuse.content && isCustomTermsOpen && (
                <Text7>
                  <p>
                    {formatText(landingInfo.termsofuse.content).map((f) => f)}
                  </p>
                </Text7>
            )}
            <Divider />
          </ContentSubContainer>
        </ContentContainer>
        {acceptedTerms ? (
          <Button onClick={onContinueClick}>Accept</Button>
        ) : (
          <ButtonInactive>Accept</ButtonInactive>
        )}
      </PageContainer>
    </SlidePage>
  );
};

export { PrivacyPolicy };
