import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { store } from '../../store.js';
import {
  SlidePage,
  HeaderFlow,
  Link,
  DateInput,
  Error,
  Text3,
  Text4,
  Text6,
  Text7,
  Button,
  ButtonInactive,
  Overlay,
} from '..';
import { createUserRec, storeImage } from '../../api';
import { imageCDN } from '../../config';
import { formatText } from '../../utils';

const PageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 1fr;
`;

const TestContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ImgPreview = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

const PdfPreview = styled.object`
  position: relative;
  width: 100%;
  height: auto;
  margin: 10px 0;
`;

const CertifyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 15px;
  cursor: pointer;
  margin: 10px 0;
`;

const Image = styled.img``;

const AddTest = (props) => {
  const gs = useContext(store);
  const {
    testVacSelector,
    testConfig,
    testData,
    vacDataPayload,
    userData,
    clientInfo,
    forceFlowAll,
  } = gs.state;

  const dirRight = props.match.params.dir === 'l' ? false : true;
  const clientPath = props.match.params.client;
  const eventPath = props.match.params.event;

  const [err, setErr] = useState('');
  const [review, setReview] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errAccount, setErrAccount] = useState(false);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_upltest_displ__');
    if (testData.testImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (testData.testImage.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(testData.testImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData.testImage]);

  useEffect(() => {
    const imgCont = document.getElementById('__vc_img_upltest_displ__');
    if (review && testData.testImage && imgCont) {
      setReview(true);
      const imgReader = new FileReader();
      imgReader.onload = function (e) {
        if (testData.testImage.type === 'application/pdf') {
          imgCont.data = e.target.result;
        } else {
          imgCont.src = e.target.result;
        }
      };
      imgReader.readAsDataURL(testData.testImage);
    } else if (imgCont) {
      imgCont.src = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review]);

  const onDateChange = (date) => {
    gs.dispatch({ type: 'set_testDate', data: date });
    setErr('');
  };

  const onClickSave = () => {
    setErr('');
    setErrAccount(false);

    if (!testData.testDate) {
      setErr(`Missing test date`);
      return;
    }

    if (!testData.testImage) {
      setErr(`Missing test image`);
      return;
    }

    if (Object.keys(userData).length === 0) {
      setErr(`Missing user account info`);
      setErrAccount(true);
      return;
    }

    setSubmitting(true);

    const imgCont = document.getElementById('__vc_img_upltest_displ__');

    const userDataObj = {};
    Object.keys(userData).forEach((k) => {
      if (!k.endsWith('_#confirm#')) userDataObj[k] = userData[k];
    });

    const imagePayload = {
      client: clientInfo.clientCode,
      event: clientInfo.clientId,
      imageType: testData.testImage.type,
      image: imgCont ? (imgCont.src ? imgCont.src : imgCont.data) : null,
    };

    storeImage(imagePayload)
      .then((res) => {
        let payload = {
          ...clientInfo,
          userData: userDataObj,
          testData: {
            testDate: testData.testDate,
            images: [{ type: testData.testImage.type, id: res.data.id }],
          },
        };

        if (forceFlowAll) {
          payload = { vacData: vacDataPayload, ...payload };
        }

        createUserRec(payload)
          .then(() => {
            setSubmitting(false);
            props.history.push(
              `/${clientPath}${eventPath ? '/' + eventPath : ''}/gtg`
            );
          })
          .catch((_) => {
            setSubmitting(false);
            setErr('Failed to save user');
          });
      })
      .catch(() => {
        setSubmitting(false);
        setErr('Failed to save test image');
      });
  };

  const navToDateChange = () => {
    setReview(false);
    setErr('');
  };

  const navToUpload = (dir) => {
    if (!testData.testDate) {
      setErr(`Please select test date`);
      return;
    }
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploadt`
    );
    setErr('');
  };

  const navToUploadToChange = (dir) => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/uploadt/l`
    );
    setErr('');
  };

  const navToAccountInfo = () => {
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/regaccount/l`
    );
  };

  const onAcceptBoxClick = () => {
    if (!review) {
      setAcceptedTerms(!acceptedTerms);
    }
  };

  const navBack = () => {
    gs.dispatch({ type: 'set_testImage', data: null });
    const nextUrl =
      testVacSelector.show && !forceFlowAll ? 'selproof' : 'regaccount';
    props.history.push(
      `/${clientPath}${eventPath ? '/' + eventPath : ''}/${nextUrl}`
    );
  };

  const onClose = () => {
    gs.dispatch({ type: 'set_testImage', data: null });
    props.history.push(`/${clientPath}/${eventPath}`);
  };

  return (
    <SlidePage right={dirRight}>
      {submitting && <Overlay />}
      <PageContainer>
        <HeaderFlow
          label={review ? 'Confirm Test Submission' : 'Test Results'}
          onLeave={review ? false : navBack}
          showClose
          closeHandler={onClose}
          {...props}
        ></HeaderFlow>
        {review ? (
          <TestContainer>
            {err && (
              <div>
                <Error style={{ margin: '0 0 20px 0' }}>{err}</Error>
                {errAccount && (
                  <Link
                    onClick={navToAccountInfo}
                    style={{ margin: '-15px 0 30px 0' }}
                  >
                    Return to Account Information
                  </Link>
                )}
              </div>
            )}
            <Link style={{ marginBottom: '10px' }} onClick={navToDateChange}>
              Change Date
            </Link>
            <DateInput
              type='date'
              defaultValue={(testData && testData.testDate) || ''}
              readOnly
            ></DateInput>
            <Link
              style={{ margin: '20px 0 5px 0' }}
              onClick={navToUploadToChange}
            >
              Upload another image
            </Link>
            {testData.testImage.type === 'application/pdf' && (
              <PdfPreview
                id='__vc_img_upltest_displ__'
                style={{ opacity: testData && testData.testImage ? 1 : 0 }}
              />
            )}
            {testData.testImage.type !== 'application/pdf' && (
              <ImgPreview
                id='__vc_img_upltest_displ__'
                style={{ opacity: testData && testData.testImage ? 1 : 0 }}
              />
            )}
          </TestContainer>
        ) : (
          <TestContainer>
            {testConfig &&
              testConfig.title &&
              testConfig.title.split('#-#').map((l, idx) => (
                <Text3
                  key={idx}
                  style={{ marginBottom: '15px', whiteSpace: 'pre-line' }}
                >
                  {formatText(l)}
                </Text3>
              ))}
            {err && <Error style={{ margin: '10px 0 0 0' }}>{err}</Error>}
            <Text6 style={{ margin: '20px 0 10px 0' }}>
              When were you tested?
            </Text6>
            <DateInput
              type='date'
              value={testData.testDate || ''}
              onChange={(e) => onDateChange(e.target.value)}
              style={{ marginBottom: '35px' }}
            ></DateInput>
            {testConfig &&
              testConfig.info &&
              testConfig.info.split('#-#').map((l, idx) => (
                <Text7
                  key={idx}
                  style={{ marginBottom: '15px', whiteSpace: 'pre-line' }}
                >
                  {formatText(l)}
                </Text7>
              ))}
            <Text4 style={{ marginTop: '10px' }}>
              <b>
                {testConfig && testConfig.submission_msg
                  ? testConfig.submission_msg
                  : 'A separate submission must be done for EACH individual/attendee.'}
              </b>
            </Text4>
            {testData.testImage &&
              testData.testImage.type === 'application/pdf' && (
                <PdfPreview
                  id='__vc_img_upltest_displ__'
                  style={{ opacity: testData && testData.testImage ? 1 : 0 }}
                />
              )}
            {testData.testImage &&
              testData.testImage.type !== 'application/pdf' && (
                <ImgPreview
                  id='__vc_img_upltest_displ__'
                  style={{ opacity: testData && testData.testImage ? 1 : 0 }}
                />
              )}
          </TestContainer>
        )}
        {testConfig.certifyText && (
          <CertifyContainer onClick={onAcceptBoxClick}>
            <Text7>{testConfig.certifyText}</Text7>
            <Image
              style={{ marginTop: '3px', marginRight: '5px', height: '25px' }}
              src={
                acceptedTerms
                  ? `${imageCDN}checkbox_checked.png`
                  : `${imageCDN}uncheckbox.png`
              }
              alt='check box'
            />
          </CertifyContainer>
        )}
        {!testConfig.certifyText || acceptedTerms ? (
          <Button onClick={review ? onClickSave : navToUpload}>
            {review ? 'Submit Test Results' : 'Continue to Upload Test Results'}
          </Button>
        ) : (
          <ButtonInactive>
            {review ? 'Submit Test Results' : 'Continue to Upload Test Results'}
          </ButtonInactive>
        )}
      </PageContainer>
    </SlidePage>
  );
};

export { AddTest };
