import React, { useEffect, useContext, useState } from 'react';
import { store } from '../../store.js';
import { NoRouteMatch, LandingPage, Overlay } from '..';

const Landing = (props) => {
  const gs = useContext(store);
  const { landingInfo } = gs.state;

  const [landingPage, setLandingPage] = useState(null);

  useEffect(() => {
    const pageName =
      landingInfo === undefined
        ? undefined
        : landingInfo == null
        ? null
        : landingInfo.page;
    setLandingPage(getLandingPage(pageName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingInfo]);

  const getLandingPage = (pageName) => {
    switch (pageName) {
      case undefined:
        return <Overlay />;
      case 'Landing':
        return <LandingPage {...props} />;
      default:
        return <NoRouteMatch />;
    }
  };

  return landingPage;
};

export { Landing };
